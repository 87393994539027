<template>
  <form ref="languages">
    <div
      class="w-100"
      v-for="(text, textKey) in filteredStrings"
      :key="textKey"
    >
      <v-textarea
        rows="2"
        v-model="model[textKey]"
        outlined
        hide-details="auto"
        class="th-mt-16"
      >
        <template v-slot:append>
          <div class="position-relative">
            <v-icon
              append-icon="mdi-restore"
              @click="resetToDefault(textKey)"
              size="18"
              color="accent"
            >
              mdi-restore
            </v-icon>
            <!--                        <span class="caption">-->
            <!--                            {{$translation.t("Reset text to default")}}-->
            <!--                        </span>-->
          </div>
        </template>
      </v-textarea>
    </div>
    <div class="text-right th-mt-24">
      <v-btn color="secondaryDark" text @click="$emit('close')">
        {{ $translation.t("Cancel") }}
      </v-btn>
      <v-btn color="primary" @click="save">
        {{ $translation.t("Save") }}
      </v-btn>
    </div>
  </form>
</template>

<script>
export default {
  name: "CookieConsentBannerMultiLanguageForm",
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    defaultLanguage: {
      type: String,
      default: "en",
    },
    defaultStrings: {
      type: Function,
    },
  },
  data: () => ({
    model: {},
  }),
  created() {
    this.getLanguageModel(this.value);
  },
  updated() {
    this.getLanguageModel(this.value);
  },
  computed: {
    filteredStrings() {
      const strings = { ...this.model };
      try {
        delete strings.poweredBy;

        return strings;
      } catch {
        // Do nothing
      }

      return this.model;
    },
    locale() {
      return this.value?.locale || this.defaultLanguage;
    },
    defaultCookieBannerStrings() {
      return this.defaultStrings(this.locale);
    },
  },
  methods: {
    getLanguageModel(newValue) {
      const language = { ...newValue };

      delete language.locale;

      for (let key in this.defaultCookieBannerStrings) {
        this.$set(
          this.model,
          key,
          language[key] || this.defaultCookieBannerStrings[key]
        );
      }
    },
    save() {
      this.$emit("save");
    },
    resetToDefault(key) {
      this.$set(this.model, key, this.defaultCookieBannerStrings[key]);
    },
  },
  watch: {
    model: {
      deep: true,
      handler: function (newValue) {
        this.$emit("input", { locale: this.locale, ...newValue });
      },
    },
    value: {
      deep: true,
      handler: function (newValue) {
        this.getLanguageModel(newValue);
      },
    },
  },
};
</script>
