<template>
  <div>
    <language-input-field
      :global="false"
      v-model="selectedLanguage"
      :items="languageList"
      code
    />
    <v-form ref="categoryForm" class="w-100">
      <v-text-field
        outlined
        hide-details="auto"
        class="th-mt-16"
        :label="$translation.t('Name')"
        v-model="model[selectedLanguage.toLowerCase()]['name']"
      >
        <template v-slot:append>
          <div class="position-relative">
            <v-icon
              append-icon="mdi-restore"
              @click="resetToDefault('name')"
              size="18"
              color="accent"
            >
              mdi-restore
            </v-icon>
          </div>
        </template>
      </v-text-field>
      <v-textarea
        outlined
        hide-details="auto"
        class="th-mt-16"
        rows="4"
        :label="$translation.t('Description')"
        v-model="model[selectedLanguage.toLowerCase()]['description']"
      >
        <template v-slot:append>
          <div class="position-relative">
            <v-icon
              append-icon="mdi-restore"
              @click="resetToDefault('description')"
              size="18"
              color="accent"
            >
              mdi-restore
            </v-icon>
          </div>
        </template>
      </v-textarea>
    </v-form>
    <div class="text-right th-mt-24">
      <v-btn color="secondaryDark" text @click="$emit('cancel')">
        {{ $translation.t("Back") }}
      </v-btn>
      <v-btn color="primary" @click="save">
        {{ $translation.t("Save") }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import rules from "@/utils/inputRules";
import LanguageSelection from "@/data/languages.json";
import LanguageInputField from "@/components/FormFields/GeneralInfoFields/LanguageInputField";

export default {
  name: "CookieConsentBannerCategoryMultilanguage",
  components: { LanguageInputField },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    defaultName: String,
    defaultDescription: String,
  },
  data: () => ({
    rules,
    selectedLanguage: "en",
    model: {
      en: {
        name: "",
        description: "",
      },
    },
  }),
  created() {
    this.init();

    this.selectedLanguage =
      this.languageList.find(({ code }) => code)?.code?.toLowerCase() || "en";
  },
  computed: {
    languageList() {
      return Object.keys(this.model)
        .map(
          (code) =>
            this.languageDetails(code) || {
              code,
              name: code?.toUpperCase(),
            }
        )
        .filter((l) => l);
    },
  },
  methods: {
    init() {
      this.model = { ...this.value.locale };
    },
    save() {
      this.$emit("input", { locale: this.model });
      this.$emit("save");
    },
    languageDetails(languageCode) {
      return LanguageSelection.find(
        ({ code }) => code === languageCode?.toLowerCase()
      );
    },
    resetToDefault(key) {
      const lang = this.selectedLanguage.toLowerCase();

      const string =
        key === "name" ? this.defaultName : this.defaultDescription;

      this.model[lang][key] = this.$translation.tl(string, lang);
    },
  },
};
</script>

<style scoped></style>
