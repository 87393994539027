import { v4 as uuidv4 } from "uuid";

const requireContext = require.context("./languages", false, /.*\.json$/);

export const languages = requireContext
  .keys()
  .map((file) => [file.replace(/(^.\/)|(\.json$)/g, ""), requireContext(file)])
  .reduce((langs, [langCode, translations]) => {
    return {
      ...langs,
      [langCode]: translations,
    };
  }, {});

const themes = [
  {
    name: "Blue",
    slug: "blue",
    mainColor: "#485AFF",
    settings: {
      barColor: "#485AFF",
      barTextColor: "#FFFFFF",
      barBorderRadius: "12px",
      barMainButtonBorderColor: "#FFFFFF",
      barMainButtonColor: "#FFFFFF",
      barMainButtonTextColor: "#485AFF",
      modalMainButtonColor: "#485AFF",
      modalMainButtonTextColor: "#FFFFFF",
    },
  },
  {
    name: "Primary",
    slug: "primary",
    mainColor: "#FFC600",
    settings: {
      barColor: "#FFC600",
      barTextColor: "#003566",
      barBorderRadius: "12px",
      barMainButtonBorderColor: "#003566",
      barMainButtonColor: "#003566",
      barMainButtonTextColor: "#FFFFFF",
      modalMainButtonColor: "#FFC600",
      modalMainButtonTextColor: "#003566",
    },
  },
  {
    name: "Secondary",
    slug: "secondary",
    mainColor: "#003566",
    settings: {
      barColor: "#003566",
      barTextColor: "#FFFFFF",
      barBorderRadius: "12px",
      barMainButtonBorderColor: "#FFC600",
      barMainButtonColor: "#FFC600",
      barMainButtonTextColor: "#003566",
      modalMainButtonColor: "#FFC600",
      modalMainButtonTextColor: "#003566",
    },
  },
  {
    name: "Light",
    slug: "light",
    mainColor: "#FFF",
    settings: {
      barColor: "#FFFFFF",
      barTextColor: "#333333",
      barBorderRadius: "12px",
      barMainButtonBorderColor: "#333333",
      barMainButtonColor: "#333333",
      barMainButtonTextColor: "#FFFFFF",
      modalMainButtonColor: "#333333",
      modalMainButtonTextColor: "#FFFFFF",
    },
  },
  {
    name: "Dark",
    slug: "dark",
    mainColor: "#333333",
    settings: {
      barColor: "#333333",
      barTextColor: "#FFFFFF",
      barBorderRadius: "12px",
      barMainButtonBorderColor: "#FFFFFF",
      barMainButtonColor: "#FFFFFF",
      barMainButtonTextColor: "#333333",
      modalMainButtonColor: "#FFFFFF",
      modalMainButtonTextColor: "#333333",
    },
  },
];

export const cookieConsentFontFamilySelect = [
  "inherit",
  "Open Sans",
  "Arial",
  "Arial Black",
  "Verdana",
  "Tahoma",
  "Trebuchet MS",
  "Impact",
  "Times New Roman",
  "Didot",
  "Georgia",
  "American Typewriter",
  "Andalé Mono",
  "Courier",
  "Lucida Console",
  "Monaco",
  "Bradley Hand",
  "Brush Script MT",
  "Luminari",
  "Comic Sans MS",
];

export const cookieConsentFontSizeSelect = [];
for (let size = 10; size < 20; size++) {
  cookieConsentFontSizeSelect.push(`${size}px`);
}

export const themeSettings = (themeSlug = "primary", params = {}) => {
  const foundTheme = themes.find(({ slug }) => slug === themeSlug);

  if (foundTheme) return foundTheme.settings;

  // if (Object.keys(params).length > 0) {
  return params;
  // }

  // return themes[0].settings;
};

export const cookieCategories = {
  necessary: {
    needed: true,
    enabled: true,
    wanted: true,
    checked: true,
    name: "ccb.necessary.name",
    description: "ccb.necessary.description",
  },
  functional: {
    needed: false,
    enabled: true,
    wanted: true,
    checked: false,
    name: "ccb.functional.name",
    description: "ccb.functional.description",
  },
  statistics: {
    needed: false,
    enabled: true,
    wanted: true,
    checked: false,
    name: "ccb.statistics.name",
    description: "ccb.statistics.description",
  },
  marketing: {
    name: "ccb.marketing.name",
    description: "ccb.marketing.description",
    needed: false,
    wanted: true,
    checked: false,
    enabled: true,
  },
};

export const cookieCategorySettings = (categoryKey, settings = {}) => {
  const categoryFromList = cookieCategories[categoryKey] || {};

  const defaultLanguage = {
    en: {
      name: categoryFromList.name,
      description: categoryFromList.description,
    },
  };

  // if(settings.locales?.length > 1) {
  //     settings.locales.forEach(locale => {
  //         translations[locale] = {
  //             name: trans.t(categoryFromList.name, locale),
  //             description: trans.t(categoryFromList.description, locale),
  //         }
  //     })
  // }

  return {
    enabled: settings.enabled ?? categoryFromList.needed ?? true,
    needed: settings.needed ?? categoryFromList.needed ?? true,
    wanted: settings.wanted ?? categoryFromList.wanted ?? true,
    checked: settings.checked ?? categoryFromList.checked ?? true,
    language: settings.language || {
      locale: defaultLanguage,
    },
  };
};

export const getCookieCategory = (categoryKey) => {
  categoryKey = categoryKey?.toLowerCase() || "";
  switch (categoryKey) {
    case "-":
    case "":
      return "functional";
    case "analytics":
      return "statistics";
    default:
      return categoryKey;
  }
};

export const categoryTransform = (categories) => {
  const transformedCategories = {};

  categories.forEach((cat) => {
    transformedCategories[cat.id] = {
      needed: cat.needed,
      wanted: cat.wanted,
      checked: cat.checked,
      language: {
        locale: {
          en: {
            name: cat.name,
            description: cat.description,
          },
        },
      },
    };
  });

  return transformedCategories;
};

export const categoryRetransform = (categories) => {
  return (
    Object.entries(categories)?.map(([id, value]) => ({
      id,
      needed: value.needed,
      wanted: value.wanted,
      checked: value.checked,
      name: value.language.locale.en.name,
      description: value.language.locale.en.description,
    })) || []
  );
};

export const serviceList = () => {
  return [];
};

export const serviceTransform = (services) => {
  const transformedServices = {};

  services.forEach((service) => {
    transformedServices[service.id] = {
      category: service.category,
      type: service.type,
      search: service.search,
      cookies:
        service.cookies?.map((cookie) => ({
          name: cookie,
          domain: "",
        })) || [],
      language: {
        locale: {
          en: {
            name: service.name,
          },
        },
      },
    };
  });

  return transformedServices;
};

export const serviceRetransform = (services) => {
  return (
    Object.entries(services || {})?.map(([id, value]) => ({
      id,
      category: value.category,
      type: value.type,
      search: value.search,
      cookies: value.cookies.map(({ name }) => name),
      name: value.language.locale.en.name,
    })) || []
  );
};

export const getCookieId = (cookieDetails) => {
  return cookieDetails?.name?.toLowerCase().replace(/\s/g, "-");
};

export const cookieToServiceValue = (cookieDetails) => {
  return {
    id: getCookieId(cookieDetails),
    name: cookieDetails.name,
    category: getCookieCategory(cookieDetails.category),
    type: cookieDetails.type || "localcookie",
    cookies: cookieDetails.cookies || null,
    search: cookieDetails.search || null,
  };
};

export const scriptToServiceValue = (scriptDetails) => {
  const url = new URL(scriptDetails.url);

  return {
    id: uuidv4(),
    name: url.host || url.hostname,
    category: getCookieCategory(scriptDetails.category),
    type: scriptDetails.resourceType || "script",
    cookies: null,
    search: scriptDetails.url || null,
  };
};

export default {
  themes,
  cookieConsentFontFamilySelect,
  cookieConsentFontSizeSelect,
};
