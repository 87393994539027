<template>
  <div>
    <h5
      class="th-mb-12 subtitle1"
      v-if="label"
      v-html="$translation.t(label)"
    ></h5>
    <div class="d-flex align-items-center">
      <div @click="model = true">
        <th-badge class="th-mr-8 cursor-pointer" :color="badgeColor(true)">
          {{ $translation.t("Yes") }}
        </th-badge>
      </div>
      <div @click="model = false">
        <th-badge
          class="cursor-pointer"
          :color="badgeColor(false)"
          @click="model = false"
        >
          {{ $translation.t("No") }}
        </th-badge>
      </div>
    </div>
  </div>
</template>

<script>
import ThBadge from "@/components/GeneralComponents/ThBadge";
export default {
  name: "YesNoAnswer",
  components: { ThBadge },
  props: {
    value: Boolean,
    label: String,
  },
  data: () => ({
    model: true,
  }),
  created() {
    this.model = this.value;
  },
  methods: {
    badgeColor(value) {
      return this.model === value ? "primary" : "neutral";
    },
  },
  watch: {
    model(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.model = newValue;
    },
  },
};
</script>

<style lang="scss" scoped></style>
