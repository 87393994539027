<template>
  <div
    class="d-flex flex-column flex-md-row align-items-center justify-content-between th-my-24"
  >
    <h6 class="subtitle2 th-mb-24 th-mb-0-md" v-if="scan">
      {{
        $translation.t("Your website was last scanned on {date}", {
          date: scannedAt,
        })
      }}
    </h6>
    <div>
      <website-scan-action
        ref="website-scan-action"
        v-show="false"
        @scanning="scanningProgress"
        @result="scanDetails"
        :initial-website-url="websiteUrl"
      />
      <v-btn color="primary" outlined @click="include" :loading="startedScan">
        {{
          scanOlderThanHour
            ? $translation.t("Scan {website} & include rules", {
                website: websiteUrl,
              })
            : $translation.t("Include rules from recent scan")
        }}
      </v-btn>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import {
  cookieToServiceValue,
  scriptToServiceValue,
} from "../../../../utils/service/CookieConsentBanner";
import WebsiteScanAction from "../../WebsiteScanAction";
export default {
  name: "CookieServiceIncludeFromScan",
  components: { WebsiteScanAction },
  props: {
    websiteUrl: String,
    project: {
      type: Object,
    },
  },
  data: () => ({
    scan: null,
    startedScan: false,
  }),
  computed: {
    scannedAt() {
      return dayjs(this.scan?.created_at).format("YYYY MM DD");
    },
    scanOlderThanHour() {
      return (
        !this.scan?.updated_at ||
        (this.scan?.updated_at &&
          dayjs().diff(dayjs(this.scan?.updated_at), "h") > 1)
      );
    },
  },
  methods: {
    include() {
      if (!this.scanOlderThanHour) {
        return this.addCookiesToCcb(this.scan);
      }

      this.$refs["website-scan-action"].scanWebsite();
    },
    async scanDetails(data) {
      if (!data?.scan) return;

      this.scan = await this.$axios.get(`/api/v1/scan/${data.scan}`);

      this.addCookiesToCcb(this.scan);
    },
    addCookiesToCcb(scan) {
      const cookiesToInclude =
        scan?.scan_info?.cookies?.map((c) => ({
          ...cookieToServiceValue(c),
          recommended: true,
        })) || [];

      const loadedScriptsToInclude =
        scan?.scan_info?.loadedScripts?.map((s) => ({
          ...scriptToServiceValue(s),
          recommended: true,
        })) || [];

      this.$emit("save", [...cookiesToInclude, ...loadedScriptsToInclude]);
      this.startedScan = false;
    },
    scanningProgress(value) {
      this.startedScan = value;
    },
  },
};
</script>

<style lang="scss" scoped></style>
