var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('th-card',{staticClass:"cookie-categories",attrs:{"body-classes":"th-p-0 th-py-8 h-100","footer-classes":"th-p-0"}},[_c('template',{slot:"header"},[_c('h6',{domProps:{"innerHTML":_vm._s(_vm.$translation.t('Languages'))}}),_c('p',{staticClass:"body2",domProps:{"innerHTML":_vm._s(
          _vm.$translation.t('Add additional languages for Cookie Consent Banner')
        )}})]),_c('template',{slot:"body"},_vm._l((_vm.languages),function(localeStrings,locale){return _c('div',{key:locale,staticClass:"d-flex align-items-center w-100 bg-neutral-light--hover cursor-pointer th-px-24 th-py-12"},[_c('div',{staticClass:"flex-grow-1"},[_vm._v(" "+_vm._s(_vm.$translation.t(_vm.languageName(locale)))+" "),_c('code',{staticClass:"th-ml-8"},[_vm._v(_vm._s(locale))])]),_c('div',[(_vm.defaultCcLanguage === locale)?_c('th-badge',{attrs:{"color":"primary","solid":""}},[_vm._v(" "+_vm._s(_vm.$translation.t("Default"))+" ")]):_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.defaultCcLanguage = locale}}},[_vm._v(" "+_vm._s(_vm.$translation.t("Make default"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.editLanguage(locale, localeStrings)}}},[_vm._v(" "+_vm._s(_vm.$translation.t("edit"))+" ")])],1)])}),0),_c('div',{staticClass:"th-p-12",attrs:{"slot":"footer"},slot:"footer"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":_vm.addNewLanguage}},[_c('v-icon',{staticClass:"th-mr-8"},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$translation.t("Add additional language"))+" ")],1)],1)],2),_c('v-dialog',{attrs:{"max-width":"750","transition":"dialog-bottom-transition"},model:{value:(_vm.showNewLanguageForm),callback:function ($$v) {_vm.showNewLanguageForm=$$v},expression:"showNewLanguageForm"}},[_c('th-card',{attrs:{"body-classes":"th-p-40"}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('h6',{staticClass:"subtitle-2 th-mb-24",domProps:{"innerHTML":_vm._s(
            _vm.$translation.t('Edit {lang} language string', {
              lang:
                this.$translation.t(_vm.languageName(this.languageForm.locale)) ||
                this.languageForm.locale,
            })
          )}}),_c('cookie-consent-banner-multi-language-form',{attrs:{"default-language":_vm.DEFAULT_LANGUAGE,"default-strings":_vm.defaultStringsForLanguage},on:{"save":_vm.saveNewLanguage,"close":function($event){_vm.showNewLanguageForm = false}},model:{value:(_vm.languageForm),callback:function ($$v) {_vm.languageForm=$$v},expression:"languageForm"}})],1)])],1),_c('v-dialog',{attrs:{"max-width":"540","transition":"dialog-bottom-transition"},model:{value:(_vm.selectLanguageShow),callback:function ($$v) {_vm.selectLanguageShow=$$v},expression:"selectLanguageShow"}},[_c('th-card',{attrs:{"body-classes":"th-p-40"}},[_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('language-input-field',{attrs:{"code":"","global":false},model:{value:(_vm.languageSelect),callback:function ($$v) {_vm.languageSelect=$$v},expression:"languageSelect"}}),_c('div',{staticClass:"text-right th-mt-24"},[_c('v-btn',{attrs:{"color":"secondaryDark","text":""},on:{"click":function($event){_vm.selectLanguageShow = false}}},[_vm._v(" "+_vm._s(_vm.$translation.t("Cancel"))+" ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editLanguage(_vm.languageSelect)}}},[_vm._v(" "+_vm._s(_vm.$translation.t("continue"))+" ")])],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }