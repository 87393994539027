<template>
  <th-card class="h-100">
    <h6>{{ $translation.t("Consent Ratio") }}</h6>
    <!--    <GChart type="PieChart" :data="chartData" :options="chartOptions" />-->
  </th-card>
</template>

<script>
// import { GChart } from "vue-google-charts";
import {
  filterByStatus,
  STATUS_ACCEPTED,
  STATUS_PARTIALLY_ACCEPTED,
  STATUS_REJECTED,
} from "../../../../utils/service/CookieConsentBanner/cookieConsentLogs";
import ThCard from "../../../GeneralComponents/ThCard";

export default {
  name: "CookieConsentChart",
  components: {
    ThCard,
    // GChart
  },
  props: {
    logs: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    chartData() {
      return [
        [this.$translation.t("Title"), this.$translation.t("Count")],
        [this.$translation.t("Accepted"), this.accepted],
        [this.$translation.t("Rejected"), this.rejected],
        [this.$translation.t("Partially Accepted"), this.partiallyAccepted],
      ];
    },
    accepted() {
      return filterByStatus(this.logs, STATUS_ACCEPTED)?.length;
    },
    rejected() {
      return filterByStatus(this.logs, STATUS_REJECTED)?.length;
    },
    partiallyAccepted() {
      return filterByStatus(this.logs, STATUS_PARTIALLY_ACCEPTED)?.length;
    },
    chartOptions() {
      return {
        chartArea: {
          left: 0,
          // width: 210,
          // height: 210,
        },
        width: 550,
        height: 210,
        enableInteractivity: false,
        fontName: "Open Sans",
        fontSize: 14,
        pieSliceText: "percentage",
        colors: ["#00B6A4", "#E15454", "#FF9C00"],
        legend: {
          position: "right",
          alignment: "center",
          textStyle: {
            color: "#424852",
            fontSize: 14,
          },
        },
        // tooltip: {
        //     trigger: "none",
        // },
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>
