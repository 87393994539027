var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('th-card',{staticClass:"th-my-24"},[_c('v-form',{ref:"cookieConsentWebsiteUrlForm",attrs:{"id":"CookieConsentWebsiteUrl"}},[_c('v-text-field',{attrs:{"outlined":"","hide-details":"auto","label":_vm.$translation.t('Website URL'),"rules":[_vm.rules.required, _vm.rules.url]},model:{value:(_vm.website_url),callback:function ($$v) {_vm.website_url=$$v},expression:"website_url"}})],1)],1),(_vm.cookieConsent.uuid)?_c('cookie-consent-banner-logs',{attrs:{"cookie-consent":_vm.cookieConsent}}):_vm._e(),_c('div',{staticClass:"row th-mt-24"},[_c('div',{staticClass:"cookie-consent__main flex-grow-1 col-12 col-md-8"},[_c('cookie-consent-banner-theme',{staticClass:"h-100",attrs:{"cookie-consent":_vm.cookieConsent,"publishing":_vm.publishing},on:{"publish":_vm.saveCookieConsentBanner},scopedSlots:_vm._u([{key:"installation",fn:function(){return [(_vm.cookieConsent.url)?_c('cookie-consent-banner-installation',{directives:[{name:"show",rawName:"v-show",value:(_vm.cookieConsent),expression:"cookieConsent"}],staticClass:"th-mt-24",attrs:{"id":"installation","cookie-consent":_vm.cookieConsent},on:{"publish":_vm.saveCookieConsentBanner}}):_vm._e()]},proxy:true}]),model:{value:(_vm.cookieConsent.params.theme),callback:function ($$v) {_vm.$set(_vm.cookieConsent.params, "theme", $$v)},expression:"cookieConsent.params.theme"}})],1),_c('div',{staticClass:"col-12 col-md-4 th-mt-24 th-mt-0-md"},[_c('cookie-consent-banner-categories',{attrs:{"cookie-consent":_vm.cookieConsent},model:{value:(_vm.cookieConsent.params.categories),callback:function ($$v) {_vm.$set(_vm.cookieConsent.params, "categories", $$v)},expression:"cookieConsent.params.categories"}})],1)]),_c('div',{staticClass:"row th-mt-24"},[_c('div',{staticClass:"col-12",class:{
        'col-md-8': _vm.showLanguageHelp,
        'col-md-12': !_vm.showLanguageHelp,
      }},[_c('cookie-consent-banner-multi-language',{attrs:{"cookie-consent":_vm.cookieConsent,"categories":_vm.categories,"project":_vm.project},model:{value:(_vm.cookieConsent.params.language),callback:function ($$v) {_vm.$set(_vm.cookieConsent.params, "language", $$v)},expression:"cookieConsent.params.language"}})],1),(_vm.showLanguageHelp)?_c('div',{staticClass:"col-12 col-md-4"},[_c('alert',{attrs:{"title":"How to change cookie consent banner language on your website?"}},[_c('div',{staticClass:"th-mt-16",attrs:{"slot":"subtitle"},slot:"subtitle"},[_c('p',[_vm._v(" "+_vm._s(_vm.$translation.t("In order to change languages you can either:"))+" ")]),_c('ul',[_c('li',{domProps:{"innerHTML":_vm._s(
                _vm.$translation.t(
                  'set {cookie} cookie in the browser with the value of the language code you want',
                  {
                    cookie: "<code>cclang</code>",
                  }
                ) +
                '<code class=\'d-block\'>document.cookie = \'cclang=en\';</code>'
              )}}),_c('li',{domProps:{"innerHTML":_vm._s(
                _vm.$translation.t(
                  'or, set global variable {variable} with the value of the language code you want',
                  {
                    variable: "<code>window.cclang</code>",
                  }
                ) + '<code class=\'d-block\'>window.cclang = \'en\';</code>'
              )}})])])])],1):_vm._e()]),_c('cookie-consent-banner-services',{staticClass:"th-mt-24",attrs:{"cookie-consent":_vm.cookieConsent,"categories":_vm.categories,"project":_vm.project},scopedSlots:_vm._u([{key:"beforeTable",fn:function(ref){
              var saveServices = ref.saveServices;
return [_c('cookie-service-include-from-scan',{attrs:{"website-url":_vm.website_url},on:{"save":saveServices}})]}}]),model:{value:(_vm.cookieConsent.params.services),callback:function ($$v) {_vm.$set(_vm.cookieConsent.params, "services", $$v)},expression:"cookieConsent.params.services"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }