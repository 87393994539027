<template>
  <div>
    <div class="row">
      <div class="col-12 col-md-6">
        <th-card class="h-100">
          <div class="d-flex flex-column h-100">
            <h6>{{ $translation.t("Cookie Consent Logs") }}</h6>

            <page-loader class="th-m-24" v-if="loading" />
            <template v-else>
              <h4 class="th-mt-12 flex-grow-1" v-if="logs.length > 0">
                {{ log.length }}
              </h4>
              <h6 class="th-mt-12 flex-grow-1 body2" else>
                {{ $translation.t("There are no entries") }}
              </h6>

              <div class="text-right" v-if="logs.length">
                <v-btn
                  text
                  color="primary"
                  :loading="exporting"
                  @click="exportLogs"
                >
                  {{ $translation.t("Export as CSV ") }}
                </v-btn>
                <v-btn
                  color="primary"
                  outlined
                  @click="showLogsTable = !showLogsTable"
                >
                  {{
                    showLogsTable
                      ? $translation.t("Hide Entries")
                      : $translation.t("View Entries")
                  }}
                </v-btn>
              </div>
            </template>
          </div>
        </th-card>
      </div>
      <div class="col-12 col-md-6 th-mt-24 th-mt-0-md" v-if="logs.length">
        <cookie-consent-chart :logs="logs" />
      </div>
    </div>
    <th-card body-classes="th-p-0" v-show="showLogsTable">
      <div class="d-flex align-items-center th-pb-16" slot="header">
        <h6
          class="flex-grow-1"
          v-html="$translation.t('Cookie Consent Logs')"
        ></h6>
        <v-btn
          text
          color="primary"
          :loading="exporting"
          @click="exportLogs"
          v-if="logs.length"
        >
          {{ $translation.t("Export as CSV ") }}
        </v-btn>
      </div>
      <template slot="body">
        <page-loader class="th-m-24" v-if="loading" />
        <template v-else>
          <v-data-table
            v-if="logs.length > 0"
            :headers="headers"
            :items="logs"
            sort-by="created_at"
            sort-desc
            :items-per-page="10"
            class="elevation-0"
            :search="search"
            :custom-filter="filter"
          >
            <template v-slot:top>
              <div class="col-12 col-md-6 col-lg-4 th-py-8">
                <v-text-field
                  outlined
                  v-model="search"
                  hide-details="auto"
                  label="Search"
                />
              </div>
            </template>
            <template v-slot:item.settings="{ item }">
              <cookie-consent-log-status :status="item.settings" />
            </template>
            <template v-slot:item.url="{ item }">
              <small class="d-inline-block max-w-180">
                {{ item.url }}
              </small>
            </template>
            <template v-slot:item.created_at="{ item }">
              {{ convertDate(item.created_at) }}
            </template>
          </v-data-table>
          <div class="th-px-24 th-pb-24" v-else>
            <p
              class="body2"
              v-html="$translation.t('There are no logs at the moment')"
            ></p>
          </div>
        </template>
      </template>
    </th-card>
  </div>
</template>

<script>
import PageLoader from "../../../PageLoader";
import CookieConsentLogStatus from "./CookieConsentLogStatus";
import CookieConsentChart from "./CookieConsentChart";
import { downloadFile } from "../../../../utils/files";
import dayjs from "dayjs";
import { escapeRegExp } from "lodash";
import ThCard from "../../../GeneralComponents/ThCard";

export default {
  name: "CookieConsentBannerLogs",
  components: {
    ThCard,
    CookieConsentChart,
    CookieConsentLogStatus,
    PageLoader,
  },
  props: {
    cookieConsent: {
      required: true,
    },
  },
  data() {
    return {
      logs: [],
      loading: true,
      search: "",
      exporting: false,
      showLogsTable: false,
    };
  },
  async created() {
    this.logs = await this.$axios.get(
      `/api/v1/business/cookie-consent/${this.cookieConsent.uuid}/logs`
    );
    this.loading = false;
  },
  computed: {
    headers() {
      return [
        {
          text: this.$translation.t("Unique Visitor ID"),
          value: "uuid",
        },
        {
          text: this.$translation.t("IP"),
          value: "ip",
        },
        {
          text: this.$translation.t("Status"),
          value: "settings",
        },
        {
          text: this.$translation.t("Accepted on URL"),
          value: "url",
        },
        { text: this.$translation.t("Date"), value: "created_at" },
      ];
    },
  },
  methods: {
    filter(value, search, item) {
      const regExp = new RegExp(escapeRegExp(search), "i");

      return (
        regExp.test(item.uuid) ||
        regExp.test(item.ip) ||
        regExp.test(item.created_at)
      );
    },
    convertDate(isoDate) {
      if (!isoDate) return null;
      return dayjs(isoDate).format("YYYY-MM-DD HH:mm:ss");
    },
    async exportLogs() {
      this.exporting = true;

      const logs = await this.$axios.get(
        `/api/v1/cookie-consent/${this.cookieConsent.uuid}/logs/export`
      );

      this.$event(
        "cookie_consent_logs_export",
        {
          cookie_consent_uuid: this.cookieConsent.uuid,
        },
        "cookie_consent_logs.export"
      );

      downloadFile(
        logs,
        `cookie_consent_log_${this.cookieConsent.uuid}.csv`,
        "text/csv"
      );

      this.exporting = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
