export function selectAndCopy(ref) {
  let textToCopy = ref.$el.querySelector("textarea");
  if (!textToCopy) textToCopy = ref.$el.querySelector("input");

  textToCopy.select();
  document.execCommand("copy");

  return true;
}

export function fallbackCopyTextToClipboard(text) {
  var textArea = document.createElement("textarea");
  textArea.value = text;

  // Avoid scrolling to bottom
  textArea.style.top = "0";
  textArea.style.left = "0";
  textArea.style.position = "fixed";

  document.body.appendChild(textArea);
  textArea.focus();
  textArea.select();

  try {
    document.execCommand("copy");
  } catch (err) {
    // console.error('Fallback: Oops, unable to copy', err);
  }

  document.body.removeChild(textArea);
}

export function copy(text) {
  if (!navigator.clipboard) {
    fallbackCopyTextToClipboard(text);
    return true;
  }
  navigator.clipboard.writeText(text);

  return true;
}

export function flagImg(lang) {
  switch (lang) {
    case "us":
    case "in":
      lang = "en";
  }

  try {
    return require(`@/assets/images/languages/${lang}.png`);
  } catch (e) {
    // do nothing
  }

  return null;
}
