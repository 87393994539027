<template>
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center">
      <div
        class="ccb-position__option"
        v-for="(style, styleKey) in displayStyles"
        :key="styleKey"
        :class="{ active: displayStyle === style.id }"
        @click="displayStyle = style.id"
      >
        <img :src="style.icon" :alt="style.name" width="24px" height="24px" />
      </div>
    </div>

    <div class="d-flex align-items-center th-ml-24">
      <div
        class="ccb-position__option"
        v-for="(p, pKey) in positions"
        :key="pKey"
        :class="{ active: position === p.id }"
        @click="position = p.id"
      >
        <img :src="p.icon" :alt="p.name" width="24px" height="24px" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieConsentBannerPositioning",
  props: {
    cookieConsent: {
      required: true,
    },
    value: {
      type: Object,
      default: () => ({
        type: "banner",
        position: "bottom",
      }),
    },
  },
  data: () => ({
    displayStyles: [
      {
        icon: require("@/assets/images/cookieconsent/displayStyles/banner.svg"),
        id: "banner",
        name: "Banner",
        positions: [
          {
            icon: require("@/assets/images/cookieconsent/positions/bottom.svg"),
            id: "bottom",
            name: "Bottom",
          },
          {
            icon: require("@/assets/images/cookieconsent/positions/top.svg"),
            id: "top",
            name: "Top",
          },
        ],
      },
      {
        icon: require("@/assets/images/cookieconsent/displayStyles/modal.svg"),
        id: "modal",
        name: "Modal",
        positions: [],
      },
      {
        icon: require("@/assets/images/cookieconsent/displayStyles/tooltip.svg"),
        id: "tooltip",
        name: "Tooltip",
        positions: [
          {
            icon: require("@/assets/images/cookieconsent/positions/bottom-right.svg"),
            id: "bottom-right",
            name: "Bottom Right",
          },
          {
            icon: require("@/assets/images/cookieconsent/positions/bottom-left.svg"),
            id: "bottom-left",
            name: "Bottom Left",
          },
          {
            icon: require("@/assets/images/cookieconsent/positions/top-right.svg"),
            id: "top-right",
            name: "Top Right",
          },
          {
            icon: require("@/assets/images/cookieconsent/positions/top-left.svg"),
            id: "top-left",
            name: "Top Left",
          },
        ],
      },
    ],
    displayStyle: "",
    position: "",
  }),
  created() {
    this.displayStyle = this.value?.type || "banner";
    this.position = this.value?.position || "bottom";
  },
  computed: {
    positions() {
      return (
        this.displayStyles.find(({ id }) => id === this.displayStyle)
          ?.positions || []
      );
    },
  },
  methods: {
    emitPositioningSettings() {
      this.$emit("input", {
        type: this.displayStyle,
        position: this.position,
      });
    },
    setPositionForType() {
      const positions =
        [
          ...this.displayStyles.find(({ id }) => id === this.displayStyle)
            ?.positions,
        ] || [];

      this.position =
        positions.find(({ id }) => this.value.position === id)?.id ||
        positions.shift()?.id;
    },
  },
  watch: {
    displayStyle() {
      this.emitPositioningSettings();

      this.setPositionForType();
    },
    position() {
      this.emitPositioningSettings();
    },
  },
};
</script>

<style lang="scss" scoped>
.ccb-position {
  &__option {
    border: 1px solid $border-color;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.active {
      background: $border-color;
    }

    &:first-child {
      border-radius: 4px 0 0 4px;
    }

    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}
</style>
