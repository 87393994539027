export const STATUS_ACCEPTED = "accepted";
export const STATUS_REJECTED = "rejected";
export const STATUS_PARTIALLY_ACCEPTED = "partially_accepted";

export const overallStatus = (settings) => {
  let agreedCount = 0;

  if (settings.necessary) agreedCount++;
  if (settings.functional) agreedCount++;
  if (settings.statistics) agreedCount++;
  if (settings.marketing) agreedCount++;

  return agreedCount >= 4
    ? STATUS_ACCEPTED
    : agreedCount <= 0
    ? STATUS_REJECTED
    : STATUS_PARTIALLY_ACCEPTED;
};

export const colorByStatus = (settings) => {
  switch (overallStatus(settings)) {
    case "accepted":
      return "success";
    case "rejected":
      return "danger";
    case "partially_accepted":
      return "warning";
  }
};

export const filterByStatus = (logs, status = STATUS_ACCEPTED) => {
  return logs.filter(({ settings }) => overallStatus(settings) === status);
};
