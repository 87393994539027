<template>
  <div>
    <v-form ref="serviceForm" class="w-100">
      <v-text-field
        :label="$translation.t('Rule')"
        v-model="model.search"
        :rules="[rules.required]"
        outlined
        hide-details="auto"
      >
        <template v-slot:append>
          <v-tooltip right>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on">mdi-information</v-icon>
            </template>
            <div class="max-w-450">
              {{
                $translation.t(
                  "Enter script full name or patter matching the script. Regular expressions are accepted"
                )
              }}
            </div>
          </v-tooltip>
        </template>
      </v-text-field>
      <v-autocomplete
        class="th-mt-16"
        outlined
        v-if="categoriesSelect.length > 0"
        item-value="id"
        item-text="name"
        :items="categoriesSelect"
        v-model="model.category"
        auto-select-first
        :label="$translation.t('Category')"
        hide-details="auto"
        :rules="[rules.required]"
      >
        <template v-slot:selection="data">
          {{ $translation.t(data.item.name) }}
        </template>
        <template v-slot:item="data">
          {{ $translation.t(data.item.name) }}
        </template>
      </v-autocomplete>
      <v-text-field
        v-else
        class="th-mt-16"
        :label="$translation.t('Category')"
        v-model="model.category"
        :rules="[rules.required]"
        outlined
        hide-details="auto"
      />
      <v-text-field
        :label="$translation.t('Name')"
        v-model="model.name"
        :rules="[rules.required]"
        outlined
        hide-details="auto"
        class="th-mt-16"
      ></v-text-field>

      <div class="th-mt-24 text-right">
        <v-btn color="secondaryDark" text @click="$emit('close')">
          {{ $translation.t("Cancel") }}
        </v-btn>
        <v-btn color="primary" @click="save">
          {{ $translation.t("Save") }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import {
  cookieCategories,
  cookieToServiceValue,
  getCookieId,
} from "../../../../utils/service/CookieConsentBanner";
import rules from "@/utils/inputRules";

const DEFAULT_MODEL = {
  id: "",
  name: "",
  category: "",
  search: "",
  type: "script",
};

export default {
  name: "ScriptServiceForm",
  props: {
    categories: {
      type: Object,
      default: () => ({}),
    },
    value: {
      type: Object,
      default: () => ({}),
    },
    cookieList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    rules,
    cookieCategories,
    model: { ...DEFAULT_MODEL },
    cookieSearch: "",
    predefinedCookies: [],
    serviceTypes: [
      { id: "script", name: "Script" },
      { id: "localcookie", name: "Cookie" },
    ],
  }),
  created() {
    if (Object.keys(this.value).length > 0) {
      this.model = this.value;
    }
  },
  computed: {
    categoriesSelect() {
      return (
        Object.entries(this.cookieCategories)?.map(([id, value]) => ({
          name: value?.name || id,
          id,
        })) || []
      );
    },
  },
  methods: {
    save() {
      if (!this.$refs.serviceForm.validate()) return;

      this.$emit("save", {
        ...cookieToServiceValue(this.model),
        originalId: this.model.originalId,
      });

      Object.assign(this.model, DEFAULT_MODEL);
    },
    removeCookie(item) {
      this.model.cookies = this.model.cookies.filter(
        (cookie) => cookie !== item
      );
    },
  },
  watch: {
    "model.name"(newValue, oldValue) {
      if (getCookieId(oldValue) === this.model.id || !oldValue) {
        this.model.id = getCookieId(newValue);
      }
    },
    model: {
      deep: true,
      handler: function (newValue) {
        this.$emit("input", newValue);
      },
    },
    value: {
      deep: true,
      handler: function (newValue) {
        Object.assign(this.model, newValue);
        this.$refs?.serviceForm?.resetValidation();
      },
    },
  },
};
</script>
