<template>
  <div class="information">
    <div class="d-flex align-items-start">
      <v-icon color="success" size="18" class="th-mr-12">
        mdi-lightbulb
      </v-icon>
      <div>
        <slot name="title">
          <h6 class="th-m-0 body1">
            <span v-html="translateTitle(title)"></span>
          </h6>
        </slot>
        <slot name="subtitle">
          <template v-if="subtitle">
            <p
              v-if="typeof subtitle === 'string'"
              v-html="translateSubTitle(subtitle)"
              class="th-mt-8 th-mb-0"
            ></p>
            <p
              v-else
              v-for="(sub, subKey) in subtitle"
              :key="subKey"
              v-html="translateSubTitle(sub)"
              class="th-mt-8 th-mb-0"
            ></p>
          </template>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    title: {
      type: String,
      required: true,
    },
    subtitle: [String, Array],
    subtitleNoTrans: Boolean,
    titleNoTrans: Boolean,
  },
  methods: {
    translateTitle(sub) {
      if (this.titleNoTrans) return sub;

      return this.$translation.t(sub);
    },
    translateSubTitle(sub) {
      if (this.subtitleNoTrans) return sub;

      return this.$translation.t(sub);
    },
  },
};
</script>

<style scoped lang="scss">
.information {
  background: $green-light;
  padding: 8px 16px;
}
</style>
