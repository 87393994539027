<template>
  <div
    class="d-flex flex-column align-items-center justify-content-center h-100"
  >
    <v-progress-circular indeterminate :size="size" color="primary" />
    <slot />
  </div>
</template>

<script>
export default {
  name: "PageLoader",
  props: {
    size: {
      type: [String, Number],
      default: 60,
    },
  },
};
</script>
