<template>
  <div>
    <th-card
      class="cookie-categories"
      body-classes="th-pb-8 th-px-0"
      footer-classes="th-p-0"
    >
      <template slot="header">
        <h6 v-html="$translation.t('Cookie Consent Banner rules')"></h6>
        <p
          class="body2"
          v-html="
            $translation.t(
              'Choose which scripts and cookies should be blocked before user consent'
            )
          "
        ></p>
      </template>
      <div slot="body">
        <div class="th-px-24">
          <slot name="beforeTable" :save-services="saveServices">
            <cookie-service-include-from-scan
              :project="project"
              @save="saveServices"
            />
          </slot>

          <v-tabs v-model="tab" slider-color="primary">
            <v-tab
              :key="COOKIES"
              :href="'#' + COOKIES"
              class="text-decoration-none"
            >
              {{ $translation.t("cookies") }}
            </v-tab>
            <v-tab
              :key="SCRIPTS"
              :href="'#' + SCRIPTS"
              class="text-decoration-none"
            >
              {{ $translation.t("scripts") }}
            </v-tab>
          </v-tabs>
        </div>
        <cookie-consent-banner-cookies-table
          v-show="tab === COOKIES"
          v-model="cookies"
          :categories="categories"
          :cookie-list="filteredCookieList"
        />
        <cookie-consent-banner-scripts-table
          v-show="tab === SCRIPTS"
          v-model="scripts"
          :categories="categories"
          :cookie-list="filteredCookieList"
        />
      </div>
    </th-card>
  </div>
</template>

<script>
// import predefinedCookies from "@storage/app/cookies.json";
import {
  cookieToServiceValue,
  scriptToServiceValue,
  serviceRetransform,
  serviceTransform,
} from "../../../../utils/service/CookieConsentBanner";
import CookieServiceIncludeFromScan from "./CookieServiceIncludeFromScan";
import CookieConsentBannerCookiesTable from "./CookieConsentBannerCookiesTable";
import CookieConsentBannerScriptsTable from "./CookieConsentBannerScriptsTable";
import ThCard from "../../../GeneralComponents/ThCard";

const COOKIES = "cookies";
const SCRIPTS = "scripts";

export default {
  name: "CookieConsentBannerServices",
  components: {
    ThCard,
    CookieConsentBannerScriptsTable,
    CookieConsentBannerCookiesTable,
    CookieServiceIncludeFromScan,
  },
  props: {
    cookieConsent: {
      required: true,
    },
    project: {
      type: Object,
      default: null,
    },
    categories: Object,
    value: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  data() {
    return {
      COOKIES,
      SCRIPTS,
      predefinedCookies: [],
      newService: {},
      cookieList: [],
      cookieServicesSettings: {},
      cookies: [],
      scripts: [],
      serviceEditInProgress: false,
      showNewServiceForm: false,
      addNewServiceForm: false,
      tab: COOKIES,
    };
  },
  created() {
    this.cookieList = this.predefinedCookies;

    if (!Array.isArray(this.value) && Object.keys(this.value).length > 0) {
      this.saveServices(serviceRetransform(this.value) || []);

      return;
    }

    const lastScanInfo = this.project?.lastScan?.scan_info || null;

    const scanCookies = (lastScanInfo?.cookies || []).map((cookie) => ({
      ...cookieToServiceValue(cookie),
      recommended: true,
    }));

    this.cookieList = [...scanCookies, ...this.cookieList];

    const scanScripts = (lastScanInfo?.loadedScripts || []).map((script) => ({
      ...scriptToServiceValue(script),
      recommended: true,
    }));

    // this.cookieServices = [...scanCookies, ...scanScripts];

    this.cookies = [...scanCookies];
    this.scripts = [...scanScripts];
  },
  computed: {
    cookieServices() {
      return [...this.cookies, ...this.scripts];
    },
    filteredCookieList() {
      return this.cookieList.filter(
        ({ id }) => !this.cookieServices.find((cookie) => cookie.id === id)
      );
    },
  },
  methods: {
    scanningProgress(value) {
      this.startedScan = value;
    },
    saveServices(services) {
      this.cookies = [
        ...services.filter(({ type }) => type === "localcookie"),
        ...this.cookies,
      ];
      this.scripts = [
        ...services.filter(({ type }) => type !== "localcookie"),
        ...this.scripts,
      ];
    },
  },
  watch: {
    cookieServices: {
      deep: true,
      handler: function (newValue) {
        this.$emit("input", serviceTransform(newValue));
      },
    },
  },
};
</script>
