<template>
  <div>
    <th-card class="th-my-24">
      <v-form ref="cookieConsentWebsiteUrlForm" id="CookieConsentWebsiteUrl">
        <v-text-field
          outlined
          hide-details="auto"
          v-model="website_url"
          :label="$translation.t('Website URL')"
          :rules="[rules.required, rules.url]"
        ></v-text-field>
      </v-form>
    </th-card>

    <cookie-consent-banner-logs
      :cookie-consent="cookieConsent"
      v-if="cookieConsent.uuid"
    />

    <div class="row th-mt-24">
      <div class="cookie-consent__main flex-grow-1 col-12 col-md-8">
        <cookie-consent-banner-theme
          class="h-100"
          :cookie-consent="cookieConsent"
          v-model="cookieConsent.params.theme"
          @publish="saveCookieConsentBanner"
          :publishing="publishing"
        >
          <template #installation>
            <cookie-consent-banner-installation
              v-if="cookieConsent.url"
              v-show="cookieConsent"
              id="installation"
              class="th-mt-24"
              :cookie-consent="cookieConsent"
              @publish="saveCookieConsentBanner"
            />
          </template>
        </cookie-consent-banner-theme>
      </div>
      <div class="col-12 col-md-4 th-mt-24 th-mt-0-md">
        <cookie-consent-banner-categories
          :cookie-consent="cookieConsent"
          v-model="cookieConsent.params.categories"
        />
      </div>
    </div>

    <div class="row th-mt-24">
      <div
        class="col-12"
        :class="{
          'col-md-8': showLanguageHelp,
          'col-md-12': !showLanguageHelp,
        }"
      >
        <cookie-consent-banner-multi-language
          :cookie-consent="cookieConsent"
          :categories="categories"
          :project="project"
          v-model="cookieConsent.params.language"
        />
      </div>
      <div class="col-12 col-md-4" v-if="showLanguageHelp">
        <alert
          title="How to change cookie consent banner language on your website?"
        >
          <div slot="subtitle" class="th-mt-16">
            <p>
              {{
                $translation.t("In order to change languages you can either:")
              }}
            </p>
            <ul>
              <li
                v-html="
                  $translation.t(
                    'set {cookie} cookie in the browser with the value of the language code you want',
                    {
                      cookie: `<code>cclang</code>`,
                    }
                  ) +
                  '<code class=\'d-block\'>document.cookie = \'cclang=en\';</code>'
                "
              ></li>
              <li
                v-html="
                  $translation.t(
                    'or, set global variable {variable} with the value of the language code you want',
                    {
                      variable: `<code>window.cclang</code>`,
                    }
                  ) + '<code class=\'d-block\'>window.cclang = \'en\';</code>'
                "
              ></li>
            </ul>
          </div>
        </alert>
      </div>
    </div>

    <cookie-consent-banner-services
      class="th-mt-24"
      :cookie-consent="cookieConsent"
      :categories="categories"
      :project="project"
      v-model="cookieConsent.params.services"
    >
      <template #beforeTable="{ saveServices }">
        <cookie-service-include-from-scan
          :website-url="website_url"
          @save="saveServices"
        />
      </template>
    </cookie-consent-banner-services>
  </div>
</template>

<script>
import CookieConsentBannerLogs from "./Logs/CookieConsentBannerLogs";
import { languages } from "../../../utils/service/CookieConsentBanner";
import CookieConsentBannerTheme from "./CookieConsentBannerTheme";
import CookieConsentBannerInstallation from "./CookieConsentBannerInstallation";
import CookieConsentBannerCategories from "./Categories/CookieConsentBannerCategories";
import CookieConsentBannerMultiLanguage from "./Multilanguage/CookieConsentBannerMultilanguage";
import Alert from "../../Questionnaire/Alert";
import CookieConsentBannerServices from "./Services/CookieConsentBannerServices";
import ThCard from "../../GeneralComponents/ThCard";
import CookieServiceIncludeFromScan from "./Services/CookieServiceIncludeFromScan";
import rules from "../../../utils/inputRules";

const DEFAULT_COOKIE_CONSENT_SETTINGS = {
  params: {
    categories: {},
    language: {},
    positioning: {},
    services: [],
    theme: {},
  },
  created_at: "",
  updated_at: "",
  url: null,
  uuid: "",
};

export default {
  name: "CookieConsentBanner",
  components: {
    CookieServiceIncludeFromScan,
    ThCard,
    CookieConsentBannerServices,
    Alert,
    CookieConsentBannerMultiLanguage,
    CookieConsentBannerCategories,
    CookieConsentBannerInstallation,
    CookieConsentBannerTheme,
    CookieConsentBannerLogs,
  },
  props: {
    value: {
      type: Object,
      default: () => DEFAULT_COOKIE_CONSENT_SETTINGS,
    },
    project: {
      type: Object,
      default: null,
    },
    publishing: Boolean,
  },
  data: () => ({
    rules,
    selectedTheme: "primary",
    cookieConsent: DEFAULT_COOKIE_CONSENT_SETTINGS,
    loading: true,
    inProgress: false,
    showErrorModal: false,
    services: {},
    themeSettings: {},
    positioningSettings: {},
    categoriesSettings: {},
    languageSetting: {
      current: "en",
      locale: {},
    },
    website_url: "",
  }),
  created() {
    this.cookieConsent = this.value || DEFAULT_COOKIE_CONSENT_SETTINGS;

    this.website_url = this.cookieConsent.params.website_url;

    for (let lang in languages) {
      const messages =
        this.$translation.instance().getLocaleMessage(lang) || {};
      this.$translation
        .instance()
        .setLocaleMessage(lang, { ...messages, ...languages[lang] });
    }
  },
  computed: {
    cookieConsentParams() {
      return {
        website_url: this.website_url,
        language: this.cookieConsent.params.language,
        theme: this.cookieConsent.params.theme,
        positioning: this.cookieConsent.params.positioning,
        categories: this.cookieConsent.params.categories,
        services: this.cookieConsent.params.services,
      };
    },
    categories() {
      return this.categoriesSettings?.categories;
    },
    showLanguageHelp() {
      return (
        Object.keys(this.cookieConsent?.params?.language?.locale || {}).length >
        1
      );
    },
  },
  methods: {
    saveCookieConsentBanner() {
      if (!this.$refs["cookieConsentWebsiteUrlForm"]?.validate()) {
        this.$nextTick(function () {
          window.scrollTo(
            0,
            document.querySelector("#CookieConsentWebsiteUrl")?.offsetTop
          );
        });
        return;
      }

      this.$emit("publish", this.cookieConsentParams);
    },
  },
  watch: {
    cookieConsentParams: {
      deep: true,
      handler: function (newCookieConsentParams) {
        this.$emit("input", {
          ...this.cookieConsent,
          params: newCookieConsentParams,
        });
      },
    },
    "value.url"(newCookieConsentUrl) {
      this.cookieConsent.url = newCookieConsentUrl;
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-consent {
  &__footer-wrapper {
    @include media-breakpoint-up(md) {
      min-width: 332px;
      margin-left: 16px;
    }
    width: 100%;
  }

  &__footer {
    transition: all 0.3s;
    position: sticky;
    top: calc(#{$header-height} + 8px);
    @include elevation12();
  }
}
</style>
