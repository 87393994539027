<template>
  <div>
    <th-card class="cookie-categories h-100">
      <template slot="header">
        <h6 v-html="$translation.t('Categories')"></h6>
        <p
          class="body2"
          v-html="
            $translation.t(
              'Choose which cookie categories you want the user to control'
            )
          "
        ></p>
      </template>
      <template slot="body">
        <div
          class="cookie-consent-banner__category th-py-8 d-flex flex-column flex-md-row align-items-md-center w-100"
          v-for="(cCategory, cCategoryKey) in defaultCookieCategoriesSettings"
          :key="cCategoryKey"
        >
          <h6
            class="body1 flex-grow-1 th-m-0"
            v-html="$translation.t(cCategory.name)"
          ></h6>
          <div class="th-ml-12 th-my-12 th-my-0-md">
            <v-btn
              color="primary"
              text
              class="cursor-pointer"
              @click="editCookieConsentCategory = cCategoryKey"
            >
              {{ $translation.t("edit") }}
            </v-btn>
          </div>
        </div>
      </template>
    </th-card>
    <v-dialog
      v-model="showEditCookieConsentCategory"
      max-width="540"
      transition="dialog-bottom-transition"
      @click:outside="editCookieConsentCategory = null"
    >
      <th-card
        body-classes="th-p-16 th-p-40-md"
        v-if="editCookieConsentCategory"
      >
        <template slot="body">
          <cookie-consent-banner-category-form
            v-model="categoriesSettings[editCookieConsentCategory]"
            @save="saveCategoryChange"
            @cancel="editCookieConsentCategory = null"
          />
        </template>
      </th-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  cookieCategories as defaultCookieCategoriesSettings,
  cookieCategorySettings,
} from "../../../../utils/service/CookieConsentBanner";
import CookieConsentBannerCategoryForm from "./CookieConsentBannerCategoryForm";
import ThCard from "../../../GeneralComponents/ThCard";

export default {
  name: "CookieConsentBannerCategories",
  components: {
    ThCard,
    CookieConsentBannerCategoryForm,
  },
  props: {
    cookieConsent: {
      required: true,
    },
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    defaultCookieCategoriesSettings,
    showEditCookieConsentCategory: false,
    editCookieConsentCategory: null,
    categoriesSettings: {},
  }),
  created() {
    this.init();
  },
  computed: {
    cookieCategoriesSettings() {
      const cCategories = {};

      for (let categoriesSettingsKey in this.categoriesSettings) {
        cCategories[categoriesSettingsKey] = cookieCategorySettings(
          categoriesSettingsKey,
          this.categoriesSettings[categoriesSettingsKey]
        );
      }

      return cCategories;
    },
    languages() {
      return Object.keys(this.cookieConsent?.params?.language?.locale || {});
    },
  },
  methods: {
    init() {
      const cookieConsentCookieCategories = { ...(this.value || {}) };

      for (let categoryKey in defaultCookieCategoriesSettings) {
        const defaultCookieCategory =
          defaultCookieCategoriesSettings[categoryKey];
        const existingCookieCategory = {
          ...cookieConsentCookieCategories[categoryKey],
        };

        const language = {};

        this.languages.forEach((lang) => {
          language[lang] = {
            name:
              existingCookieCategory?.language?.locale[lang]?.name ||
              this.$translation.tl(defaultCookieCategory?.name, lang),
            description:
              existingCookieCategory?.language?.locale[lang]?.description ||
              this.$translation.tl(defaultCookieCategory?.description, lang),
          };
        });

        this.$set(this.categoriesSettings, categoryKey, {
          ...defaultCookieCategory,
          ...existingCookieCategory,
          enabled: true,
          language: {
            locale: language,
          },
        });
      }
    },
    saveCategoryChange() {
      this.$emit("input", this.cookieCategoriesSettings);
      this.editCookieConsentCategory = null;
    },
  },
  watch: {
    cookieCategoriesSettings: {
      deep: true,
      handler: function (newValue) {
        this.$emit("input", newValue);
      },
    },
    editCookieConsentCategory(newValue) {
      this.showEditCookieConsentCategory = !!newValue;
    },
    languages() {
      this.init();
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-consent-banner {
  &__category {
    &:not(:last-child) {
      border-bottom: 1px solid $neutral-mid;
    }
  }
}
</style>
