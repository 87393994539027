<template>
  <div v-if="this.cookieConsentUrl">
    <h6
      class="subtitle1"
      v-html="$translation.t('Install cookie consent')"
    ></h6>
    <p
      class="body2"
      v-text="
        $translation.t(
          'Copy the code below and paste it into very top of &lt;head&gt; section of your website'
        )
      "
    ></p>
    <div
      class="d-flex flex-column flex-md-row align-items-md-center justify-content-md-between th-my-16"
    >
      <h6
        class="body1 th-mb-12 th-mb-0-md"
        v-html="$translation.t('Code snippet')"
      ></h6>
      <v-btn color="primary" outlined small @click="copyLink">
        <v-icon size="16" class="th-mr-8">mdi-content-copy</v-icon>
        {{ $translation.t("Copy to clipboard") }}
      </v-btn>
    </div>
    <code class="cookie-consent__code" ref="cookieConsentCode">
      <span class="d-block text-neutral-dark"> {{ `&lt;html>` }}<br /> </span>
      <span class="d-block text-neutral-dark th-pl-12">
        {{ `&lt;head>` }}<br />
      </span>
      <span class="d-block th-pl-12">{{ script }}</span>
      <span class="d-block text-neutral-dark th-pl-12">
        {{ `&lt;title>...&lt;/title>` }}
      </span>
      <span class="d-block text-neutral-dark">
        {{ "&lt;/head>" }}<br />
        {{ "&lt;body>" }}<br />
        {{ "..." }}
      </span>
    </code>
  </div>
</template>

<script>
import { copy } from "@/utils/service/helpers";

export default {
  name: "CookieConsentBannerInstallation",
  props: {
    project: {
      type: Object,
      default: null,
    },
    cookieConsent: {
      required: true,
    },
  },
  data: () => ({}),
  computed: {
    script() {
      return (
        "<" +
        `script src="${this.cookieConsentUrl}"` +
        ' type="text/javascript"><' +
        "/script>"
      );
    },
    cookieConsentUrl() {
      return this.cookieConsent?.url;
    },
  },
  methods: {
    copyLink() {
      if (copy(this.script)) {
        this.$toasted.info(this.$translation.t("Copied"), {
          icon: "mdi-content-copy",
        });
      }

      this.$event(`cookie_consent_install_copy`, {
        project_id: this.$route.params.project_id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-consent__code {
  display: block;
  padding: 16px 24px;
  background: $neutral-light;
  border: 1px solid $neutral-mid;
  box-sizing: border-box;
  border-radius: 8px;
  overflow: hidden;
  word-break: break-all;
}
</style>
