export function capitalise(str) {
  return str ? str.charAt(0).toUpperCase() + str.slice(1) : str;
}

export function kebabToCamel(string, pascal = false) {
  let converter = (matches) => {
    return matches[1]?.toUpperCase();
  };

  let result = string?.replace(/(-\w)/g, converter);

  if (pascal) {
    result = result?.charAt(0)?.toUpperCase() + result?.slice(1);
  }

  return result;
}

export function snakeToCamel(string, pascal = false) {
  let converter = (matches) => {
    return matches[1]?.toUpperCase();
  };

  let result = string?.replace(/(_\w)/g, converter);

  if (pascal) {
    result = result?.charAt(0)?.toUpperCase() + result?.slice(1);
  }

  return result;
}

export function snakeToReadable(str) {
  return str?.replace(/_/g, " ");
}

export function convertToRealValue(str) {
  switch (str) {
    case "true":
      return true;
    case "false":
      return false;
    default:
      return str;
  }
}

export function toUrl(url = "") {
  const modifiedUrl =
    url?.toLowerCase().replace(/^(htts?p(s?):\/\/)?/, "http$2://") || url;

  if (!modifiedUrl.match(/^https?:\/\//)) {
    return "http://" + modifiedUrl;
  }

  return modifiedUrl;
}
