var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-data-table',{staticClass:"th-mt-24",attrs:{"items":_vm.scripts,"headers":_vm.header,"items-per-page":-1,"search":_vm.search,"custom-filter":_vm.filter,"no-data-text":_vm.$translation.t('No scripts'),"hide-default-footer":""},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex justify-content-end th-px-24"},[_c('div',{staticClass:"col-12 col-md-6 col-lg-4 text-right"},[_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.addNewService}},[_c('v-icon',{staticClass:"th-mr-8"},[_vm._v("mdi-plus")]),_vm._v(" "+_vm._s(_vm.$translation.t("Add new rule"))+" ")],1)],1)])]},proxy:true},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-items-center"},[_c('h6',{staticClass:"body1 th-mb-0",domProps:{"innerHTML":_vm._s(item.name)}}),(item.provider && item.provider !== '-')?_c('span',{staticClass:"body2 text-neutral-dark th-ml-8"},[_vm._v(" | "+_vm._s(item.provider)+" ")]):_vm._e()])]}},{key:"item.search",fn:function(ref){
var value = ref.value;
return [_c('code',{staticClass:"d-inline-block caption max-w-360 text-break"},[_vm._v(" "+_vm._s(value)+" ")])]}},{key:"item.category",fn:function(ref){
var value = ref.value;
return [_c('p',{staticClass:"body2 flex-grow-1 text-capitalize",domProps:{"innerHTML":_vm._s(_vm.$translation.t(value))}})]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"cursor-pointer",attrs:{"text":"","color":"primary"},on:{"click":function($event){return _vm.editService(item)}}},[_vm._v(" "+_vm._s(_vm.$translation.t("edit"))+" ")]),_c('v-btn',{staticClass:"cursor-pointer",attrs:{"text":"","color":"error"},on:{"click":function($event){return _vm.deleteService(item)}}},[_vm._v(" "+_vm._s(_vm.$translation.t("Delete"))+" ")])]}}])}),_c('v-dialog',{attrs:{"max-width":"540","transition":"dialog-bottom-transition"},model:{value:(_vm.showNewServiceForm),callback:function ($$v) {_vm.showNewServiceForm=$$v},expression:"showNewServiceForm"}},[_c('th-card',{attrs:{"body-classes":"th-p-40"}},[_c('script-service-form',{attrs:{"categories":_vm.categories,"cookie-list":_vm.cookieList},on:{"save":_vm.saveService,"close":function($event){_vm.showNewServiceForm = false}},model:{value:(_vm.editModel),callback:function ($$v) {_vm.editModel=$$v},expression:"editModel"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }