<template>
  <div v-if="!showTranslationSettings">
    <slot name="header">
      <div class="d-flex align-items-center justify-content-between">
        <h5 class="th-mb-12" v-html="$translation.t(model.name)"></h5>
        <v-icon
          class="th-ml-8"
          size="18"
          color="secondaryDark"
          @click="showTranslationSettings = true"
        >
          mdi-translate
        </v-icon>
      </div>
      <p class="body2" v-html="$translation.t(model.description)"></p>
    </slot>
    <v-form ref="categoryForm" class="w-100">
      <yes-no-answer
        class="th-mt-24"
        label="Are this category cookies needed?"
        v-model="model.needed"
      />
      <yes-no-answer
        class="th-mt-24"
        label="Should this category cookies should be set before user consent?"
        v-model="model.wanted"
      />
      <yes-no-answer
        class="th-mt-24"
        label="Should this category cookies be allowed by default?"
        v-model="model.checked"
      />

      <div class="text-right th-mt-24">
        <v-btn color="secondaryDark" text @click="$emit('cancel')">
          {{ $translation.t("Cancel") }}
        </v-btn>
        <v-btn color="primary" @click="save">
          {{ $translation.t("Save") }}
        </v-btn>
      </div>
    </v-form>
  </div>
  <cookie-consent-banner-category-multilanguage
    v-else
    v-model="languageSettings"
    :default-name="this.model.name"
    :default-description="this.model.description"
    @save="saveLanguageSettings"
    @cancel="showTranslationSettings = false"
  />
</template>

<script>
import rules from "../../../../utils/inputRules";
import YesNoAnswer from "../../../FormFields/YesNoAnswer";
import CookieConsentBannerCategoryMultilanguage from "./CookieConsentBannerCategoryMultilanguage";
import { cloneDeep } from "lodash";

export default {
  name: "CookieConsentBannerCategoryForm",
  components: {
    CookieConsentBannerCategoryMultilanguage,
    YesNoAnswer,
  },
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    rules,
    showTranslationSettings: false,
    languageSettings: {
      locale: {
        en: {
          name: "",
          description: "",
        },
      },
    },
    model: {
      id: "",
      needed: true,
      wanted: true,
      checked: false,
      language: {},
      name: "",
      description: "",
    },
  }),
  created() {
    this.init();
  },
  // updated() {
  //     this.init();
  // },
  methods: {
    init() {
      if (Object.keys(this.value).length > 0) {
        this.model = cloneDeep(this.value);
      }

      this.languageSettings = cloneDeep(this.value.language);
    },
    saveLanguageSettings() {
      this.model.language = this.languageSettings;
      this.showTranslationSettings = false;
    },
    toId(value) {
      return value.toLowerCase().replace(/\s/g, "-");
    },
    save() {
      if (!this.$refs.categoryForm.validate()) return;

      this.$emit("save", this.model);
    },
  },
  watch: {
    "model.name"(newValue, oldValue) {
      if (this.toId(oldValue) === this.model.id || !oldValue) {
        this.model.id = this.toId(newValue);
      }
    },
    model: {
      deep: true,
      handler: function (newValue) {
        this.$emit("input", newValue);
      },
    },
    value: {
      deep: true,
      handler: function (newValue) {
        this.model = newValue;
      },
    },
  },
};
</script>

<style scoped></style>
