<template>
  <div>
    <v-tooltip right>
      <template v-slot:activator="{ on }">
        <div class="d-inline-block" v-on="on">
          <th-badge :color="colorByStatus(status)">
            {{ $translation.t(overallStatus(status)) }}
          </th-badge>
        </div>
      </template>
      <ul class="text-capitalize">
        <li>
          {{ $translation.t("necessary") }} :
          {{ statusByCategory("necessary") }}
        </li>
        <li>
          {{ $translation.t("functional") }} :
          {{ statusByCategory("functional") }}
        </li>
        <li>
          {{ $translation.t("statistics") }} :
          {{ statusByCategory("statistics") }}
        </li>
        <li>
          {{ $translation.t("marketing") }} :
          {{ statusByCategory("marketing") }}
        </li>
      </ul>
    </v-tooltip>
  </div>
</template>

<script>
import ThBadge from "@/components/GeneralComponents/ThBadge";
import {
  colorByStatus,
  overallStatus,
} from "@/utils/service/CookieConsentBanner/cookieConsentLogs";
export default {
  name: "CookieConsentLogStatus",
  components: { ThBadge },
  props: {
    status: {
      type: Object,
      required: true,
      default: () => ({
        necessary: true,
        functional: true,
        statistics: true,
        marketing: true,
      }),
    },
  },
  methods: {
    overallStatus,
    colorByStatus,
    statusByCategory(cat) {
      return this.status[cat]
        ? this.$translation.t("accepted")
        : this.$translation.t("rejected");
    },
  },
};
</script>

<style lang="scss" scoped></style>
