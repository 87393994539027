<template>
  <span
    class="th-badge"
    :class="[`th-badge--${color}`, classes]"
    @click="$emit('click')"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: "ThBadge",
  props: {
    color: {
      type: String,
      default: "primary",
    },
    solid: Boolean,
    outlined: Boolean,
    xs: Boolean,
    sm: Boolean,
    lg: Boolean,
    xl: Boolean,
  },
  computed: {
    classes() {
      return {
        solid: this.solid,
        outlined: this.outlined,
        "th-badge--xs": this.xs,
        "th-badge--sm": this.sm,
        "th-badge--lg": this.lg,
        "th-badge--xl": this.xl,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
$th-badge-sizes: (
  "xs": 18,
  "sm": 20,
  "default": 36,
  "lg": 44,
  "xl": 52,
);

$th-badge-line-height: (
  "xs": 16px,
  "sm": 18px,
  "default": 34px,
  "lg": 42px,
  "xl": 50px,
);

$th-badge-font-sizes: (
  "xs": 10px,
  "sm": 12px,
  "default": 14px,
  "lg": 14px,
  "xl": 16px,
);

.th-badge {
  display: inline-block;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-sizing: border-box;
  border-radius: 24px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0.001em;
  padding: 0 12px;
  line-height: map-get($th-badge-line-height, "default");
  min-height: #{map-get($th-badge-sizes, "default")} + "px";
  font-size: map-get($th-badge-font-sizes, "default");

  @each $size, $value in $th-badge-sizes {
    &--#{$size} {
      min-height: #{$value} + "px";
      line-height: map-get($th-badge-line-height, $size);
      font-size: map-get($th-badge-font-sizes, $size);
    }
  }

  /* Color variants */
  @each $color, $value in $theme-colors {
    $textColor: text-color($color);
    $outlineColor: $color;

    @if ($textColor == null) {
      $textColor: $white;
    }

    @if ($outlineColor == null) {
      $outlineColor: $value;
    }

    &--#{$color} {
      background: $value; //array-get($th-color-variants, $color, "light");
      border: 1px solid $value;
      color: $textColor;
    }

    &--#{$color}.solid {
      background: $value;
      border: 1px solid $value;
      color: $textColor;
    }

    &--#{$color}.outlined {
      background: transparent;
      border: 1px solid $value;
      color: $value;
    }
  }
}
</style>
