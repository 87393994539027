<template>
  <th-card>
    <template slot="body">
      <div class="d-flex align-items-center">
        <h6
          class="overline th-mb-0 th-mr-12 text-uppercase"
          v-html="$translation.t('Quick Themes')"
        ></h6>
        <div class="d-flex flex-wrap flex-md-nowrap align-items-center">
          <a
            href
            v-for="(theme, themeKey) in themes"
            :key="themeKey"
            class="theme-color text-decoration-none"
            :class="`theme-color--${theme.slug}`"
            :style="`background-color: ${theme.mainColor}`"
            @click.prevent="selectedTheme = theme.slug"
          >
            <v-icon v-if="selectedTheme === theme.slug" color="light" size="18">
              mdi-check
            </v-icon>
          </a>
        </div>
      </div>
      <div class="row th-mt-24">
        <div class="col-12 col-lg-6">
          <cookie-consent-banner-preview
            ref="ccb_preview"
            class="th-mb-24"
            :cookie-consent="cookieConsent"
            :full-params="cookieConsent.params"
          />
        </div>
        <div class="col-12 col-lg-6">
          <div class="row">
            <div class="col-8">
              <v-autocomplete
                outlined
                :items="cookieConsentFontFamilySelect"
                v-model="theme.fontFamily"
                auto-select-first
                :label="$translation.t('Font family')"
                hide-details="auto"
              >
                <template v-slot:selection="{ item }">
                  <span class="text-capitalize">
                    {{ item }}
                  </span>
                </template>
                <template v-slot:item="{ item }">
                  <span class="text-capitalize">
                    {{ item }}
                  </span>
                </template>
              </v-autocomplete>
            </div>
            <div class="col-4">
              <v-text-field
                outlined
                v-model="theme.fontSize"
                :label="$translation.t('Font size')"
                hide-details="auto"
              />
            </div>
            <div class="col-12 col-md-6 th-mt-12">
              <th-color-picker
                :label="$translation.t('Font color')"
                v-model="theme.barTextColor"
              />
            </div>
            <div class="col-12 col-md-6 th-mt-12">
              <v-text-field
                outlined
                v-model="theme.barBorderRadius"
                :label="$translation.t('Border radius')"
                hide-details="auto"
              />
            </div>
            <div class="col-12 col-md-6 th-mt-12">
              <th-color-picker
                :label="$translation.t('Banner background')"
                v-model="theme.barColor"
              />
            </div>
            <div class="col-12 col-md-6 th-mt-12">
              <th-color-picker
                :label="$translation.t('Button background')"
                v-model="theme.barMainButtonColor"
              />
            </div>
            <div class="col-12 col-md-6 th-mt-12">
              <th-color-picker
                :label="$translation.t('Button border color')"
                v-model="theme.barMainButtonBorderColor"
              />
            </div>
            <div class="col-12 col-md-6 th-mt-12">
              <th-color-picker
                :label="$translation.t('Button text color')"
                v-model="theme.barMainButtonTextColor"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="row th-mt-24">
        <div class="col-12 col-lg-6 th-mt-12 th-mt-0-lg">
          <cookie-consent-banner-positioning
            v-model="cookieConsent.params.positioning"
            :cookie-consent="cookieConsent"
          />
        </div>
        <div
          class="col-12 col-lg-6 th-mt-24 th-mt-0-lg d-flex align-items-center justify-content-end"
        >
          <v-btn
            color="primary"
            class="th-mr-8"
            outlined
            @click="enablePreview"
          >
            <v-icon class="th-mr-8">mdi-eye</v-icon>
            {{ $translation.t("Preview") }}
          </v-btn>
          <v-btn color="primary" @click="publish" :loading="publishing" :disabled="!getCurrentSubscription()">
            {{ $translation.t("Publish") }}
          </v-btn>
        </div>
      </div>
      <v-divider class="th-my-16" v-if="$slots.installation" />
      <slot name="installation" />
    </template>
  </th-card>
</template>

<script>
import cookieConsentBanner, {
  themeSettings,
  cookieConsentFontFamilySelect,
  cookieConsentFontSizeSelect,
} from "../../../utils/service/CookieConsentBanner";
import CookieConsentBannerPreview from "./CookieConsentBannerPreview";
import ThColorPicker from "../../FormFields/th-color-picker";
import ThCard from "../../GeneralComponents/ThCard";
import CookieConsentBannerPositioning from "./CookieConsentBannerPositioning";
import {mapGetters} from "vuex";

export default {
  name: "CookieConsentBannerTheme",
  components: {
    CookieConsentBannerPositioning,
    ThCard,
    ThColorPicker,
    CookieConsentBannerPreview,
  },
  props: {
    cookieConsent: {
      required: true,
    },
    value: {
      type: Object,
      default: () => ({
        slug: "primary",
        theme: {},
      }),
    },
    publishing: Boolean,
  },
  // inject: ['inProgress'],
  data: () => ({
    cookieConsentFontFamilySelect,
    cookieConsentFontSizeSelect,
    selectedTheme: "primary",
    theme: {
      fontFamily: "inherit",
      fontSize: "1rem",
      barTextColor: "#003566",
      barColor: "#FFC600",
      barMainButtonColor: "#003566",
      barMainButtonTextColor: "#ffffff",
      barBorderRadius: "12px",
      barMainButtonBorderColor: "#003566",
    },
    inProgress: false,
  }),
  created() {
    this.selectedTheme = this.value.slug ?? "primary";
    const settings = themeSettings(this.selectedTheme);

    this.theme = {
      ...this.theme,
      ...settings,
      ...this.value,
    };

    if (!this.theme) this.setThemeColors();
  },
  computed: {
    themes() {
      return cookieConsentBanner.themes;
    },
    ...mapGetters(["getCurrentSubscription"]),
  },
  methods: {
    setThemeColors(settings = null) {
      if (!settings) settings = themeSettings(this.selectedTheme);

      this.theme = {
        ...this.theme,
        ...settings,
        slug: this.selectedTheme,
      };
    },
    enablePreview() {
      try {
        this.$refs.ccb_preview?.enablePreview();
      } catch {
        // do nothing
      }
    },
    publish() {
      this.$emit("publish");
    },
  },
  watch: {
    selectedTheme(newTheme) {
      const settings = themeSettings(newTheme);

      this.setThemeColors(settings);

      this.$emit("input", {
        ...this.theme,
        slug: newTheme,
      });
    },
    theme: {
      deep: true,
      handler: function (newThemeValues) {
        this.$emit("input", {
          ...newThemeValues,
          slug: this.selectedTheme,
        });
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.theme-color {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &--sm {
    width: 24px;
    height: 24px;
  }

  &--light {
    border: 1px solid $text-secondary;

    .v-icon {
      color: $dark !important;
      caret-color: $dark !important;
    }
  }
}

.theme-color-wrapper {
  padding: 8px 24px;
  border: 1px solid $border-color;
  background-color: $white;
  border-radius: 12px;
}
</style>
