<template>
  <v-text-field
    v-bind="$attrs"
    v-model="color"
    hide-details="auto"
    outlined
    @click="menu = true"
    class="cursor-pointer"
  >
    <template v-slot:prepend-inner>
      <v-menu
        v-model="menu"
        top
        nudge-bottom="105"
        nudge-left="16"
        :close-on-content-click="false"
      >
        <template v-slot:activator="{ on }">
          <div :style="swatchStyle" v-on="on" />
        </template>
        <v-card>
          <v-card-text class="th-p-0">
            <v-color-picker v-model="color" flat />
            <div class="th-p-8 text-right">
              <v-btn
                class="th-mr-8"
                color="primary"
                outlined
                small
                @click="menu = false"
              >
                {{ $translation.t("Cancel") }}
              </v-btn>
              <v-btn color="primary" small @click="menu = false">
                {{ $translation.t("Confirm") }}
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-menu>
    </template>
  </v-text-field>
</template>

<script>
import tinycolor from "tinycolor2";

export default {
  name: "th-color-picker",
  props: {
    value: String,
    swatchSize: {
      type: String,
      default: "24px",
    },
  },
  data: () => ({
    // swatchColor: '#001D3DFF',
    color: "#001D3DFF",
    mask: "!#XXXXXXXX",
    menu: false,
  }),
  created() {
    this.color = this.value || "#001D3DFF";
  },
  computed: {
    swatchStyle() {
      const { color } = this;

      const borderColor =
        tinycolor(color).getBrightness() > 200 ? "#001D3D" : "transparent";

      return {
        backgroundColor: color,
        cursor: "pointer",
        height: this.swatchSize,
        width: this.swatchSize,
        borderRadius: "50%",
        border: `1px solid ${borderColor}`,
        transition: "border-radius 200ms ease-in-out",
      };
    },
  },
  watch: {
    color(newColor) {
      this.$emit("input", newColor);
      // this.swatchColor = newColor;
    },
    // swatchColor(newSelectedColor) {
    //     this.color = newSelectedColor;
    // },
    value(newColorValue) {
      this.color = newColorValue;
    },
  },
};
</script>

<style lang="scss" scoped></style>
