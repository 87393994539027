import { translation } from "@/utils/service/Translations/translations";

const validations = {
  required: (value) => !!value || translation.t("Required."),
  atLeastOne: (value) =>
    !!Object.values(value).find((v) => v) || translation.t("Required."),
  counter: (value) => value.length <= 20 || "Max 20 characters",
  email: (value) => {
    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return pattern.test(value) || translation.t("Invalid e-mail.");
  },
  website: (value) => {
    const pattern = /.*/gim;
    return pattern.test(value) || translation.t("Invalid website url");
  },
  url: (value) => {
    const pattern =
      /^(https?:\/\/)?(www.)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,10}(:[0-9]{1,5})?(\/.*)?$/gim;
    return pattern.test(value) || translation.t("Invalid URL");
  },
  number: (value) => {
    return !isNaN(value) || translation.t("Value must be a number");
  },
  password8: (value) => {
    return (
      value.length >= 8 ||
      translation.t("Password is too short: min 8 characters")
    );
  },
  price: (value) => {
    const pattern = /^\d+(\.|,)?\d*$/gi;
    return pattern.test(value) || translation.t("Must be a valid amount");
  },
};

const decoyValidation = () => true;

export const keyToValidationFunction = (key) => {
  return validations[key] || decoyValidation;
};

export default validations;
