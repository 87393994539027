<template>
  <div>
    <div class="cookie-consent__prev">
      <div class="cookie-consent__prev-header">
        <div class="cookie-consent__prev-header-dot red th-mr-8"></div>
        <div class="cookie-consent__prev-header-dot yellow th-mr-8"></div>
        <div class="cookie-consent__prev-header-dot green th-mr-8"></div>
        <div class="cookie-consent__prev-header-line flex-grow-1"></div>
      </div>
      <div
        class="cookie-consent__prev-content"
        :class="{
          'cookie-consent__prev-content--open': showFullPreview,
        }"
      >
        <div class="cookie-consent__prev-content-close">
          <v-btn color="primary" @click="disablePreview">
            <v-icon class="th-mr-8" size="24">mdi-close</v-icon>
            {{ $translation.t("Close preview") }}
          </v-btn>
        </div>
        <iframe
          class="cookie-consent__prev-content-iframe"
          :srcdoc="content"
        ></iframe>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "CookieConsentBannerPreview",
  components: {},
  props: {
    cookieConsent: {
      required: true,
    },
    fullParams: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    showFullPreview: false,
  }),
  computed: {
    preparedParamsForPreview() {
      return { ...this.fullParams };
    },
    content() {
      const barClasses = this.showFullPreview ? "" : "huge";

      return (
        "<" +
        "script src='https://app.termshub.io/termshub/termshub-cookie-consent-preview-v2.min.js'>" +
        "<" +
        "/script>" +
        "<" +
        "script>" +
        "window.CookieConsent.init(" +
        JSON.stringify({
          ...this.preparedParamsForPreview,
          noAnimation: true,
          barTimeout: 0,
          setCookie: false,
          barClasses,
        }) +
        ");" +
        "<" +
        "/script>"
      );
    },
  },
  methods: {
    enablePreview() {
      const vAppBar = document.querySelector(".v-app-bar");
      if (vAppBar) vAppBar.style.zIndex = -1;

      const vNavigationDrawer = document.querySelector(".v-navigation-drawer");
      if (vNavigationDrawer) vNavigationDrawer.style.zIndex = -1;

      this.showFullPreview = true;
    },
    disablePreview() {
      const vAppBar = document.querySelector(".v-app-bar");
      if (vAppBar) vAppBar.style.zIndex = null;

      const vNavigationDrawer = document.querySelector(".v-navigation-drawer");
      if (vNavigationDrawer) vNavigationDrawer.style.zIndex = null;

      this.showFullPreview = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.cookie-consent {
  &__prev {
    background: $white;
    border: 1px solid $neutral-light;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    min-height: 130px;
    @include elevation2();
  }

  &__prev-header {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }

  &__prev-header-dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;

    &.red {
      background: $red-dark;
    }
    &.yellow {
      background: $primary-mid;
    }
    &.green {
      background: $green-mid;
    }
  }

  &__prev-header-line {
    height: 8px;
    background: $neutral-light;
    border-radius: 24px;
  }

  &__prev-content {
    background: $neutral-light;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    min-height: 130px;
    height: 130px;

    &--open {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 20;
      width: 100%;
      height: 100%;
      background: rgba($primary-dark, 0.8);
    }
  }

  &__prev-content--open &__prev-content-close {
    display: block;
  }
  &__prev-content--open &__prev-content-iframe {
    width: 100%;
    height: 100%;
    transform: scale(1);
  }

  &__prev-content-close {
    display: none;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 24px;
    z-index: 30;
  }

  &__prev-content-iframe {
    border: 0;
    width: 1000%;
    height: 1300px;
    transform: scale(0.1);
    transform-origin: 0 0;
  }
}
</style>
