<template>
  <page-loader v-if="loading" />
  <div
    v-else
    class="position-relative"
    id="cookieConsentView"
    ref="cookieConsentView"
  >
    <cookie-consent-banner
      :key="cookieConsentKey"
      v-model="cookieConsent"
      :project="project"
      :publishing="inProgress"
      @publish="saveCookieConsentBanner"
    />
  </div>
</template>

<script>
import PageLoader from "../../../components/PageLoader";
import CookieConsentBanner from "../../../components/Project/CookieConsent/CookieConsentBanner";

export default {
  name: "CookieConsentBannerView",
  components: {
    CookieConsentBanner,
    PageLoader,
  },
  props: {
    onSuccess: {
      type: Function,
      default: null,
    },
  },
  data: () => ({
    cookieConsent: null,
    loading: true,
    inProgress: false,
    project: null,
  }),
  async beforeCreate() {
    try {
      this.cookieConsent = await this.$axios.get(
        `/api/v1/business/cookie-consent/${this.$route.params.uuid}`
      );
    } catch (e) {
      this.$log.notify(e);
    }

    this.loading = false;
  },
  computed: {
    projectName() {
      return this.project?.name || "";
    },
    cookieConsentKey() {
      return this.cookieConsent
        ? this.cookieConsent.uuid
        : "cookie-consent-banner";
    },
  },
  methods: {
    async saveCookieConsentBanner() {
      this.inProgress = true;
      try {
        const cookieConsent = await this.$axios.put(
          `/api/v1/business/cookie-consent/${this.$route.params.uuid}`,
          {
            params: this.cookieConsent.params,
          }
        );

        this.$event(`cookie_consent_updated`);

        this.cookieConsent = cookieConsent;

        if (typeof this.onSuccess === "function")
          return this.onSuccess(cookieConsent);

        this.$nextTick(function () {
          window.scrollTo(
            0,
            document.querySelector("#installation")?.offsetTop
          );
        });

        this.inProgress = false;
      } catch (e) {
        if (e.showError !== false) {
          this.$toasted.error("Error", {
            icon: "mdi-close-octagon-outline",
          });

          this.error = e.response?.data?.message;
          this.showErrorModal = true;
        }

        this.inProgress = false;

        this.$log.notify(e);
      }
    },
    // ...mapMutations("projects", ["UPDATE_PROJECT_COOKIE_CONSENT"]),
  },
};
</script>
