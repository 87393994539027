<template>
  <div>
    <v-data-table
      class="th-mt-24"
      :items="scripts"
      :headers="header"
      :items-per-page="-1"
      :search="search"
      :custom-filter="filter"
      :no-data-text="$translation.t('No scripts')"
      hide-default-footer
    >
      <template v-slot:top>
        <div class="d-flex justify-content-end th-px-24">
          <!--                    <div class="col-12 col-md-6 col-lg-4">-->
          <!--                        <v-text-field-->
          <!--                            outlined-->
          <!--                            v-model="search"-->
          <!--                            :label="$translation.t('Search')"-->
          <!--                        />-->
          <!--                    </div>-->
          <div class="col-12 col-md-6 col-lg-4 text-right">
            <v-btn color="primary" @click="addNewService">
              <v-icon class="th-mr-8">mdi-plus</v-icon>
              {{ $translation.t("Add new rule") }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="d-flex align-items-center">
          <h6 class="body1 th-mb-0" v-html="item.name"></h6>
          <span
            class="body2 text-neutral-dark th-ml-8"
            v-if="item.provider && item.provider !== '-'"
          >
            | {{ item.provider }}
          </span>
        </div>
      </template>
      <template v-slot:item.search="{ value }">
        <code class="d-inline-block caption max-w-360 text-break">
          {{ value }}
        </code>
      </template>
      <template v-slot:item.category="{ value }">
        <p
          class="body2 flex-grow-1 text-capitalize"
          v-html="$translation.t(value)"
        ></p>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          text
          class="cursor-pointer"
          @click="editService(item)"
          color="primary"
        >
          {{ $translation.t("edit") }}
        </v-btn>
        <v-btn
          text
          color="error"
          class="cursor-pointer"
          @click="deleteService(item)"
        >
          {{ $translation.t("Delete") }}
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showNewServiceForm"
      max-width="540"
      transition="dialog-bottom-transition"
    >
      <th-card body-classes="th-p-40">
        <script-service-form
          v-model="editModel"
          :categories="categories"
          :cookie-list="cookieList"
          @save="saveService"
          @close="showNewServiceForm = false"
        />
      </th-card>
    </v-dialog>
  </div>
</template>

<script>
import { escapeRegExp, cloneDeep } from "lodash";
import rules from "@/utils/inputRules";
import { cookieCategories } from "../../../../utils/service/CookieConsentBanner";
import ScriptServiceForm from "./ScriptServiceForm";
import ThCard from "../../../GeneralComponents/ThCard";

export default {
  name: "CookieConsentBannerScriptsTable",
  components: { ThCard, ScriptServiceForm },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    cookieList: Array,
    categories: Object,
  },
  data() {
    return {
      rules,
      search: "",
      scripts: [],
      editModel: {},
      serviceEditInProgress: false,
      showNewServiceForm: false,
    };
  },
  created() {
    this.scripts = this.value.map((c) => cloneDeep(c));
  },
  computed: {
    categoriesSelect() {
      return (
        Object.entries(cookieCategories)?.map(([id, value]) => ({
          name: value?.name || id,
          id,
        })) || []
      );
    },
    header() {
      return [
        {
          text: this.$translation.t("Name"),
          value: "name",
        },
        {
          text: this.$translation.t("Rule"),
          value: "search",
        },
        {
          text: this.$translation.t("Category"),
          value: "category",
        },
        {
          text: "",
          align: "right",
          value: "action",
        },
      ];
    },
  },
  methods: {
    filter(value, search, item) {
      const regExp = new RegExp(escapeRegExp(search), "i");

      return regExp.test(item.name) || regExp.test(item.provider);
    },
    deleteService(service) {
      this.scripts = this.scripts.filter(({ id }) => id !== service.id);
      this.$emit("input", this.scripts);
    },
    editService(cookie) {
      this.showNewServiceForm = true;
      this.editModel = { originalId: cookie.id, ...cloneDeep(cookie) };
      this.serviceEditInProgress = true;
    },
    addNewService() {
      this.showNewServiceForm = true;
      this.serviceEditInProgress = true;
    },
    saveService(newService) {
      if (this.serviceEditInProgress) {
        let editedService = this.scripts.find(
          ({ id }) => id === newService.originalId
        );

        delete newService.originalId;

        if (editedService) Object.assign(editedService, newService);
        else this.scripts?.push(newService);

        this.$emit("input", this.scripts);
        this.serviceEditInProgress = false;
      }

      this.showNewServiceForm = false;
      this.editModel = {};
    },
  },
  watch: {
    value: {
      deep: true,
      handler: function (newServices) {
        this.scripts = newServices;
        // this.$emit('input', newCookies);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
