<template>
  <v-autocomplete
    ref="inputField"
    v-bind="$attrs"
    outlined
    :items="items || LanguageSelection"
    v-model="model"
    :rules="[rules.required]"
    :item-value="code ? 'code' : 'name'"
    :filter="filter"
    :label="label || $translation.t('Language')"
    name="language"
    auto-select-first
    hide-details="auto"
    :readonly="disabled"
    :class="{ 'input-disabled': disabled }"
  >
    <template v-slot:selection="data">
      <div class="d-flex align-items-center">
        <span class="mr-3" v-if="data.item.flag">
          {{ data.item.flag }}
        </span>
        {{ $translation.t(data.item.name) }}
      </div>
    </template>
    <template v-slot:item="data">
      <div class="d-flex align-items-center">
        <span class="mr-3" v-if="data.item.flag">
          {{ data.item.flag }}
        </span>
        {{ $translation.t(data.item.name) }}
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import rules from "../../../utils/inputRules";
import LanguageSelection from "../../../data/languages.json";
import { escapeRegExp } from "lodash";
import projectInputFields from "../../../utils/mixins/projectInputFields";

export default {
  name: "LanguageInputField",
  props: {
    label: String,
    value: String,
    code: Boolean,
    items: [Array, Object],
    global: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [projectInputFields],
  data: () => ({
    rules,
    LanguageSelection,
    model: "",
  }),
  created() {
    this.model = this.findCountry(this.value || "lt");
  },
  methods: {
    filter(item, queryText) {
      const q = new RegExp(escapeRegExp(queryText.toLowerCase()));
      return (
        item?.name?.toLowerCase().match(q) ||
        this.$translation.t(item?.name)?.toLowerCase().match(q) ||
        item?.code?.toLowerCase().match(q)
      );
    },
    findCountry(languageValue) {
      if (!languageValue) return languageValue;

      const language = LanguageSelection.find(
        ({ name, code }) => name === languageValue || code === languageValue
      );

      if (!language) return languageValue;

      return language[this.code ? "code" : "name"];
    },
  },
  watch: {
    model(newLanguage) {
      this.$emit("input", newLanguage);
    },
    value(newLanguage) {
      this.model = newLanguage;
    },
  },
};
</script>

<style scoped></style>
