export const downloadFile = (
  content,
  title = "document.pdf",
  type = "application/pdf"
) => {
  const linkSource = `data:${type},${content}`;
  const downloadLink = document.createElement("a");
  const fileName = title;
  downloadLink.href = linkSource;
  downloadLink.download = fileName;
  downloadLink.click();
};
