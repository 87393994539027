<template>
  <div>
    <div v-show="step === 1">
      <v-combobox
        v-model="model"
        :items="cookieList"
        :search-input.sync="search"
        hide-selected
        outlined
        :filter="filter"
        :label="$translation.t('Rule')"
      >
        <template v-slot:item="{ item }">
          <div class="d-inline-flex flex-column th-py-12">
            <div class="d-inline-flex align-items-center">
              <code class="subtitle2 th-mr-12">
                {{ item.name }}
              </code>
              <p
                class="body2 text-capitalize"
                v-html="$translation.t(item.category)"
              ></p>
            </div>
            <p class="caption max-w-450 th-mt-8">
              {{ item.purpose }}
            </p>
          </div>
        </template>
        <template v-slot:selection="{ item }">
          <code v-if="typeof item === 'string'">{{ item }}</code>
          <div v-else class="d-flex align-items-center">
            <code class="subtitle2 th-mr-12">{{ item.name }}</code>
            <p
              class="body2 text-capitalize"
              v-html="$translation.t(item.category)"
            ></p>
          </div>
        </template>
        <template v-slot:no-data v-if="search">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title
                v-html="
                  $translation.t(
                    'No results matching {search}. Press {button} to create a new one',
                    {
                      search:
                        '<' +
                        'strong><code>{{ search }}' +
                        '</code><' +
                        '/strong>',
                      button: '<' + 'kbd>enter' + '<' + '/kbd>',
                    }
                  )
                "
              >
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-combobox>
      <div class="text-right">
        <v-btn color="secondaryDark" text @click="$emit('close')">
          {{ $translation.t("Cancel") }}
        </v-btn>
        <v-btn color="primary" :disabled="!model" @click="next">
          {{ $translation.t("Next") }}
        </v-btn>
      </div>
    </div>
    <cookie-service-form
      v-if="step === 2"
      v-model="newService"
      @save="saveService"
      @close="step = 1"
    >
      <v-btn
        v-slot:cancel="{ close }"
        color="secondaryDark"
        text
        @click="close"
      >
        {{ $translation.t("Back") }}
      </v-btn>
    </cookie-service-form>
    <!--                <cookie-service-form-->
    <!--                    v-if="showManualForm"-->
    <!--                    v-model="newService"-->
    <!--                    @save="saveService"-->
    <!--                    @close="showManualForm = false"-->
    <!--                />-->
    <!--        <template v-else>-->
    <!--            <cookie-service-table-->
    <!--                :project="project"-->
    <!--                :cookie-list="cookieList"-->
    <!--                v-model="newCookies"-->
    <!--            />-->
    <!--            <div-->
    <!--                class="d-flex align-items-center justify-content-between flex-wrap th-mt-24"-->
    <!--            >-->
    <!--                <v-btn color="secondaryDark" @click="showManualForm = true" text>-->
    <!--                    {{ $translation.t("Add cookie details manually") }}-->
    <!--                </v-btn>-->
    <!--                <div class="text-right">-->
    <!--                    <v-btn color="secondaryDark" text @click="$emit('close')">-->
    <!--                        {{ $translation.t("Cancel") }}-->
    <!--                    </v-btn>-->
    <!--                    <v-btn color="primary" @click="save">-->
    <!--                        {{ $translation.t("Save") }}-->
    <!--                    </v-btn>-->
    <!--                </div>-->
    <!--            </div>-->
    <!--        </template>-->
  </div>
</template>

<script>
import CookieServiceForm from "./CookieServiceForm";
import { escapeRegExp, cloneDeep } from "lodash";

export default {
  name: "CookieServicesAddNew",
  components: { CookieServiceForm },
  props: {
    project: {
      type: Object,
      required: true,
    },
    cookieList: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    search: "",
    showManualForm: false,
    newCookies: [],
    newService: {},
    model: "",
    step: 1,
  }),
  methods: {
    saveService(newCookieEntry) {
      this.$emit("save", [newCookieEntry]);
      this.newService = {};
    },
    save() {
      this.$emit("save", this.newCookies);
    },
    filter(item, queryText) {
      const regex = new RegExp(escapeRegExp(queryText?.toLowerCase()));

      return regex.test(item.name?.toLowerCase());
    },
    next() {
      if (typeof this.model === "string") {
        this.newService = {
          category: "functional",
          cookies: [""],
          id: this.model,
          name: this.model,
          search: this.model,
          provider: "",
          purpose: "",
          type: "script",
        };
      } else {
        this.newService = cloneDeep(this.model);
      }
      this.step++;
    },
  },
};
</script>

<style lang="scss" scoped></style>
