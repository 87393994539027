<template>
  <div>
    <v-btn
      color="primary"
      outlined
      @click="scanWebsite"
      :loading="scanInProgress"
    >
      {{ cta || $translation.t("Scan now") }}
    </v-btn>
    <v-dialog v-model="showDialog" max-width="540">
      <th-card body-classes="th-p-40">
        <h6 class="th-mb-24">
          {{ $translation.t("Enter the URL of the website to scan") }}
        </h6>
        <v-form ref="website_scan_url">
          <v-text-field
            v-model="websiteUrl"
            :label="$translation.t('Website URL')"
            outlined
            :rules="[rules.required, rules.url]"
            hide-details="auto"
            class="th-mb-24"
          />
          <div class="text-right">
            <v-btn
              color="primary"
              text
              @click="showDialog = false"
              :disabled="scanInProgress"
            >
              {{ $translation.t("Cancel") }}
            </v-btn>
            <v-btn
              color="primary"
              @click="scanWebsite"
              :loading="scanInProgress"
            >
              {{ cta || $translation.t("Scan now") }}
            </v-btn>
          </div>
        </v-form>
      </th-card>
    </v-dialog>
  </div>
</template>

<script>
import Pusher from "pusher-js";
import rules from "../../utils/inputRules";
import { toUrl } from "../../utils/textUtilities";
import ThCard from "../GeneralComponents/ThCard";

export default {
  name: "WebsiteScanAction",
  components: { ThCard },
  props: {
    cta: String,
    initialWebsiteUrl: String,
  },
  data() {
    return {
      rules,
      scanInProgress: false,
      showDialog: false,
      websiteUrl: "",
    };
  },
  created() {
    this.websiteUrl = this.initialWebsiteUrl || "";
  },
  methods: {
    async scanWebsite() {
      if (
        (!this.websiteUrl && !this.initialWebsiteUrl) ||
        (this.$refs["website_scan_url"] &&
          !this.$refs["website_scan_url"].validate())
      ) {
        this.showDialog = true;
        return;
      }

      this.showDialog = false;

      this.scanInProgress = true;

      this.$emit("scanning", true);

      this.connectToPusher();

      try {
        const scanData = {
          url: toUrl(this.websiteUrl || this.initialWebsiteUrl),
        };

        await this.$axios.post("/api/v1/scan", scanData);
      } catch (e) {
        this.scanInProgress = false;
        this.$emit("scanning", false);
        if (e.showError !== false) {
          this.$toasted.error("Error occurred while scanning website", {
            icon: "mdi-close-octagon-outline",
          });
        }

        this.$log.notify(e);
      }
    },
    connectToPusher() {
      const clientToken = this.$store.state.auth.token;
      const clientUuid = this.$store.state.client?.uuid;

      try {
        const pusher = new Pusher("511441de67d871ad6de3", {
          cluster: "us2",
          forceTLS: true,
          authEndpoint: `${process.env.VUE_APP_API}/broadcasting/auth`,
          auth: {
            headers: {
              Authorization: "Bearer " + clientToken,
            },
          },
        });

        const channel = pusher.subscribe(`private-website_scan_${clientUuid}`);

        const self = this;

        channel.bind("website_scan_completed", async (data) => {
          await self.websiteScanCompleted(data);
          pusher.disconnect();
        });
      } catch (e) {
        this.$log.notify(e);
      }
    },
    async websiteScanCompleted(data) {
      this.$emit("result", data);

      if (this.scanInProgress) {
        this.$emit("scanning", false);
        this.scanInProgress = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
