<template>
  <div>
    <v-data-table
      class="th-mt-24"
      :items="cookies"
      :headers="header"
      :items-per-page="-1"
      :search="search"
      :custom-filter="filterCookies"
      :no-data-text="$translation.t('No cookies')"
      hide-default-footer
    >
      <template v-slot:top>
        <div class="d-flex justify-content-end th-px-24">
          <!--                    <div class="col-12 col-md-6 col-lg-4">-->
          <!--                        <v-text-field-->
          <!--                            outlined-->
          <!--                            v-model="search"-->
          <!--                            :label="$translation.t('Search')"-->
          <!--                        />-->
          <!--                    </div>-->
          <div class="col-12 col-md-6 col-lg-4 text-right">
            <v-btn color="primary" @click="addNewService">
              <v-icon class="th-mr-8">mdi-plus</v-icon>
              {{ $translation.t("Add new rule") }}
            </v-btn>
          </div>
        </div>
      </template>
      <template v-slot:item.name="{ item }">
        <div class="d-flex align-items-center">
          <h6 class="body1 th-mb-0" v-html="item.name"></h6>
          <span
            class="body2 text-neutral-dark th-ml-8"
            v-if="item.provider && item.provider !== '-'"
          >
            | {{ item.provider }}
          </span>
        </div>
      </template>
      <template v-slot:item.category="{ value }">
        <p
          class="body2 flex-grow-1 text-capitalize"
          v-html="$translation.t(value)"
        ></p>
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          text
          class="cursor-pointer"
          @click="editService(item)"
          color="primary"
        >
          {{ $translation.t("edit") }}
        </v-btn>
        <v-btn
          text
          color="error"
          class="cursor-pointer"
          @click="deleteService(item)"
        >
          {{ $translation.t("Delete") }}
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog
      v-model="showNewServiceForm"
      max-width="540"
      transition="dialog-bottom-transition"
    >
      <th-card body-classes="th-p-40">
        <cookie-service-form
          slot="body"
          v-model="editModel"
          :categories="categories"
          :cookie-list="cookieList"
          @save="saveService"
          @close="showNewServiceForm = false"
        />
      </th-card>
    </v-dialog>
    <v-dialog
      v-model="addNewServiceForm"
      max-width="540"
      transition="dialog-bottom-transition"
    >
      <th-card body-classes="th-p-40">
        <h6 class="th-mb-24">{{ $translation.t("Add new cookie rule") }}</h6>
        <cookie-services-add-new
          :project="{}"
          :categories="categories"
          :cookie-list="cookieList"
          @save="saveServices"
          @close="addNewServiceForm = false"
        />
      </th-card>
    </v-dialog>
  </div>
</template>

<script>
import { escapeRegExp, cloneDeep } from "lodash";
import rules from "../../../../utils/inputRules";
import { cookieCategories } from "../../../../utils/service/CookieConsentBanner";
import ThCard from "../../../GeneralComponents/ThCard";
import CookieServiceForm from "./CookieServiceForm";
import CookieServicesAddNew from "./CookieServicesAddNew";

export default {
  name: "CookieConsentBannerCookiesTable",
  components: { CookieServicesAddNew, CookieServiceForm, ThCard },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    cookieList: Array,
    categories: Object,
  },
  data() {
    return {
      rules,
      search: "",
      cookies: [],
      editModel: null,
      serviceEditInProgress: false,
      showNewServiceForm: false,
      addNewServiceForm: false,
    };
  },
  created() {
    this.cookies = this.value.map((c) => cloneDeep(c));
  },
  computed: {
    categoriesSelect() {
      return (
        Object.entries(cookieCategories)?.map(([id, value]) => ({
          name: value?.name || id,
          id,
        })) || []
      );
    },
    header() {
      return [
        {
          text: this.$translation.t("Rule"),
          value: "name",
        },
        {
          text: this.$translation.t("Category"),
          value: "category",
        },
        // {
        //     text: this.$translation.t("Provider"),
        //     value: "provider",
        // },
        // {
        //     text: this.$translation.t("Purpose"),
        //     value: "purpose",
        //     default: "-"
        // },
        {
          text: "",
          align: "right",
          value: "action",
        },
      ];
    },
  },
  methods: {
    filterCookies(value, search, item) {
      const regExp = new RegExp(escapeRegExp(search), "i");

      return regExp.test(item.name) || regExp.test(item.provider);
    },
    deleteService(service) {
      this.cookies = this.cookies.filter(({ id }) => id !== service.id);
      this.$emit("input", this.cookies);
    },
    editService(cookie) {
      this.showNewServiceForm = true;
      this.editModel = { originalId: cookie.id, ...cloneDeep(cookie) };
      this.serviceEditInProgress = true;
    },
    saveService(newService) {
      if (this.serviceEditInProgress) {
        let editedService = this.cookies.find(
          ({ id }) => id === newService.originalId
        );

        delete newService.originalId;

        if (editedService) Object.assign(editedService, newService);

        this.$emit("input", this.cookies);
        this.serviceEditInProgress = false;
      }

      // this.cookieServices?.push(newService);
      this.showNewServiceForm = false;
      this.addNewServiceForm = false;
      this.editModel = {};
    },
    saveServices(newServices) {
      this.cookies = this.cookies.filter(
        ({ id }) => !newServices.find((c) => c.id === id)
      );
      this.cookies = this.cookies.concat(newServices);

      this.showNewServiceForm = false;
      this.addNewServiceForm = false;
      this.newService = {};
    },
    addNewService() {
      this.addNewServiceForm = true;
      this.serviceEditInProgress = true;
    },
  },
  watch: {
    value: {
      deep: true,
      handler: function (newCookies) {
        this.cookies = newCookies;
        // this.$emit('input', newCookies);
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
