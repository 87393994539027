<template>
  <div>
    <th-card
      class="cookie-categories"
      body-classes="th-p-0 th-py-8 h-100"
      footer-classes="th-p-0"
    >
      <template slot="header">
        <h6 v-html="$translation.t('Languages')"></h6>
        <p
          class="body2"
          v-html="
            $translation.t('Add additional languages for Cookie Consent Banner')
          "
        ></p>
      </template>
      <template slot="body">
        <div
          v-for="(localeStrings, locale) in languages"
          :key="locale"
          class="d-flex align-items-center w-100 bg-neutral-light--hover cursor-pointer th-px-24 th-py-12"
        >
          <div class="flex-grow-1">
            {{ $translation.t(languageName(locale)) }}
            <code class="th-ml-8">{{ locale }}</code>
          </div>
          <div>
            <th-badge v-if="defaultCcLanguage === locale" color="primary" solid>
              {{ $translation.t("Default") }}
            </th-badge>
            <v-btn
              v-else
              text
              color="primary"
              @click="defaultCcLanguage = locale"
            >
              {{ $translation.t("Make default") }}
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="editLanguage(locale, localeStrings)"
            >
              {{ $translation.t("edit") }}
            </v-btn>
          </div>
        </div>
      </template>
      <div class="th-p-12" slot="footer">
        <v-btn text color="primary" @click="addNewLanguage">
          <v-icon class="th-mr-8">mdi-plus</v-icon>
          {{ $translation.t("Add additional language") }}
        </v-btn>
      </div>
    </th-card>
    <v-dialog
      v-model="showNewLanguageForm"
      max-width="750"
      transition="dialog-bottom-transition"
    >
      <th-card body-classes="th-p-40">
        <div slot="body">
          <h6
            class="subtitle-2 th-mb-24"
            v-html="
              $translation.t('Edit {lang} language string', {
                lang:
                  this.$translation.t(languageName(this.languageForm.locale)) ||
                  this.languageForm.locale,
              })
            "
          ></h6>
          <cookie-consent-banner-multi-language-form
            v-model="languageForm"
            @save="saveNewLanguage"
            @close="showNewLanguageForm = false"
            :default-language="DEFAULT_LANGUAGE"
            :default-strings="defaultStringsForLanguage"
          />
        </div>
      </th-card>
    </v-dialog>
    <v-dialog
      v-model="selectLanguageShow"
      max-width="540"
      transition="dialog-bottom-transition"
    >
      <th-card body-classes="th-p-40">
        <div slot="body">
          <language-input-field v-model="languageSelect" code :global="false" />
          <div class="text-right th-mt-24">
            <v-btn
              color="secondaryDark"
              text
              @click="selectLanguageShow = false"
            >
              {{ $translation.t("Cancel") }}
            </v-btn>
            <v-btn color="primary" @click="editLanguage(languageSelect)">
              {{ $translation.t("continue") }}
            </v-btn>
          </div>
        </div>
      </th-card>
    </v-dialog>
  </div>
</template>

<script>
import CookieConsentBannerMultiLanguageForm from "./CookieConsentBannerMultilanguageForm";
import LanguageSelection from "@/data/languages.json";
import { escapeRegExp } from "lodash";
import rules from "@/utils/inputRules";
import LanguageInputField from "@/components/FormFields/GeneralInfoFields/LanguageInputField";
import ThCard from "../../../GeneralComponents/ThCard";
import ThBadge from "../../../GeneralComponents/ThBadge";

const DEFAULT_LANGUAGE = "en";

export default {
  name: "CookieConsentBannerMultiLanguage",
  components: {
    ThBadge,
    ThCard,
    LanguageInputField,
    CookieConsentBannerMultiLanguageForm,
  },
  props: {
    cookieConsent: {
      required: true,
    },
    project: Object,
    categories: Object,
    value: {
      type: Object,
      default: () => ({
        current: DEFAULT_LANGUAGE,
        locale: {},
      }),
    },
  },
  data: () => ({
    rules,
    DEFAULT_LANGUAGE,
    // listOfCountries: [DEFAULT_LANGUAGE],
    languageSelect: "",
    selectLanguageShow: false,
    showNewLanguageForm: false,
    languages: {},
    languageForm: {},
    defaultCcLanguage: DEFAULT_LANGUAGE,
  }),
  created() {
    const defaultLanguage = (
      this.value.current ||
      this.$route.params.locale ||
      DEFAULT_LANGUAGE
    ).toLowerCase();

    this.defaultCcLanguage = defaultLanguage;

    this.languages = {
      [defaultLanguage]: this.defaultStringsForLanguage(defaultLanguage),
      ...(this.value.locale || {}),
    };
  },
  computed: {
    ccLanguageList() {
      return Object.keys(this.languages)
        .map(
          (code) =>
            this.language(code) || {
              code,
              name: code?.toUpperCase(),
            }
        )
        .filter((l) => l);
    },
  },
  methods: {
    editLanguage(langCode, language = {}) {
      this.showNewLanguageForm = true;

      const languageCode = langCode.toLowerCase();

      this.languageForm = {
        locale: languageCode,
        ...this.defaultStringsForLanguage(languageCode, language),
        ...this.languages[languageCode],
      };

      this.selectLanguageShow = false;
    },
    saveNewLanguage() {
      this.$set(this.languages, this.languageForm.locale, this.languageForm);

      this.showNewLanguageForm = false;
    },
    addNewLanguage() {
      this.selectLanguageShow = true;
    },
    language(languageCode) {
      return LanguageSelection.find(
        ({ code }) => code === languageCode?.toLowerCase()
      );
    },
    languageName(languageCode) {
      return this.language(languageCode)?.name;
    },
    filter(item, queryText) {
      const q = new RegExp(escapeRegExp(queryText.toLowerCase()));
      return (
        item?.name?.toLowerCase().match(q) ||
        this.$translation.t(item?.name)?.toLowerCase().match(q) ||
        item?.flag?.toLowerCase().match(q)
      );
    },
    defaultStringsForLanguage(l, language = {}) {
      return {
        barMainText:
          language[l]?.barMainText ||
          this.$translation.tl("ccb.barMainText", l),
        barLinkSetting:
          language[l]?.barLinkSetting ||
          this.$translation.tl("ccb.barLinkSetting", l),
        barBtnAcceptAll:
          language[l]?.barBtnAcceptAll ||
          this.$translation.tl("ccb.barBtnAcceptAll", l),
        modalMainTitle:
          language[l]?.modalMainTitle ||
          this.$translation.tl("ccb.modalMainTitle", l),
        modalMainText:
          language[l]?.modalMainText ||
          this.$translation.tl("ccb.modalMainText", l),
        modalBtnSave:
          language[l]?.modalBtnSave ||
          this.$translation.tl("ccb.modalBtnSave", l),
        modalBtnAcceptAll:
          language[l]?.modalBtnAcceptAll ||
          this.$translation.tl("ccb.modalBtnAcceptAll", l),
        modalAffectedSolutions:
          language[l]?.modalAffectedSolutions ||
          this.$translation.tl("ccb.modalAffectedSolutions", l),
        learnMore:
          language[l]?.learnMore || this.$translation.tl("ccb.learnMore", l),
        on: language[l]?.on || this.$translation.tl("ccb.on", l),
        off: language[l]?.off || this.$translation.tl("ccb.off", l),
        poweredBy:
          language[l]?.poweredBy || this.$translation.tl("ccb.poweredBy", l),
      };
    },
    fullLanguageSettings() {
      const ccbLocales = this.ccLanguageList
        .map(({ code }) => code?.toLowerCase())
        .filter((l) => l);

      const globalTranslations = {};

      ccbLocales.forEach(
        (l) =>
          (globalTranslations[l] = this.defaultStringsForLanguage(
            l,
            this.languages
          ))
      );

      if (Object.keys(globalTranslations).length <= 0)
        globalTranslations[DEFAULT_LANGUAGE] =
          this.defaultStringsForLanguage(DEFAULT_LANGUAGE);

      this.$emit("input", {
        current: (
          this.defaultCcLanguage ||
          this.$route.params.locale ||
          DEFAULT_LANGUAGE
        )?.toLowerCase(),
        locale: globalTranslations,
      });
    },
  },
  watch: {
    defaultCcLanguage() {
      this.fullLanguageSettings();
    },
    languages: {
      deep: true,
      handler: function () {
        this.fullLanguageSettings();
      },
    },
  },
};
</script>
